/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetPaymentLinkSaleDataResponseDto
 */
export interface GetPaymentLinkSaleDataResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetPaymentLinkSaleDataResponseDto
     */
    billet_url?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPaymentLinkSaleDataResponseDto
     */
    billet_expires_in?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPaymentLinkSaleDataResponseDto
     */
    sale_type?: GetPaymentLinkSaleDataResponseDtoSaleTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum GetPaymentLinkSaleDataResponseDtoSaleTypeEnum {
    Boleto = 'boleto',
    Pos = 'pos',
    PaymentLink = 'payment_link',
    ProtestLink = 'protest_link',
    Online = 'online',
    AutoGeneratePaymentLink = 'auto_generate_payment_link',
    AutoGenerateProtestLink = 'auto_generate_protest_link',
    BilletCode = 'billet_code',
    BillPayment = 'bill_payment',
    Digital = 'digital'
}

export function GetPaymentLinkSaleDataResponseDtoFromJSON(json: any): GetPaymentLinkSaleDataResponseDto {
    return GetPaymentLinkSaleDataResponseDtoFromJSONTyped(json, false);
}

export function GetPaymentLinkSaleDataResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetPaymentLinkSaleDataResponseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'billet_url': !exists(json, 'billet_url') ? undefined : json['billet_url'],
        'billet_expires_in': !exists(json, 'billet_expires_in') ? undefined : json['billet_expires_in'],
        'sale_type': !exists(json, 'sale_type') ? undefined : json['sale_type'],
    };
}

export function GetPaymentLinkSaleDataResponseDtoToJSON(value?: GetPaymentLinkSaleDataResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'billet_url': value.billet_url,
        'billet_expires_in': value.billet_expires_in,
        'sale_type': value.sale_type,
    };
}


