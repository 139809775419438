/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetAcquirerDto
 */
export interface GetAcquirerDto {
    /**
     * 
     * @type {string}
     * @memberof GetAcquirerDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetAcquirerDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof GetAcquirerDto
     */
    code: GetAcquirerDtoCodeEnum;
}

/**
* @export
* @enum {string}
*/
export enum GetAcquirerDtoCodeEnum {
    Zoop = 'zoop',
    Adyen = 'adyen',
    Bradesco = 'bradesco',
    Itau = 'itau',
    BtgDigital = 'btg_digital',
    Rede = 'rede',
    Btg = 'btg'
}

export function GetAcquirerDtoFromJSON(json: any): GetAcquirerDto {
    return GetAcquirerDtoFromJSONTyped(json, false);
}

export function GetAcquirerDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAcquirerDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'code': json['code'],
    };
}

export function GetAcquirerDtoToJSON(value?: GetAcquirerDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'code': value.code,
    };
}


