import React from 'react';

import { Flex, Stack, Image, FormControl, Alert, AlertIcon } from '@chakra-ui/react';

import { useHistory, useLocation } from 'react-router-dom';
import { Formik } from 'formik';
import { useToasts } from 'react-toast-notifications';

import * as yup from 'yup';

import FormInput from '../../components/Form/FormInput';
import FormErrorMessage from '../../components/Form/FormErrorMessage';
import FormLabel from '../../components/Form/FormLabel';
import FormPasswordInput from '../../components/Form/FormPasswordInput';
import Button from '../../components/Button';
import Loader from '../../containers/Loader';

import { parseExceptionMessage } from '../../services/api.service';
import { useAuth } from '../../contexts/AuthProvider';

import { Configuration, ResetPasswordRequestDto, AuthApi } from '../../clients';
import Text from '../../components/Text';
import { logoUrl } from '../../config/variables';

const ForgotPassword: React.FC = () => {
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [error, setError] = React.useState<boolean>(false);

	const history = useHistory();
	const location = useLocation();

	const search = location.search;
	const params = new URLSearchParams(search);
	const token = params.get('token') || '';

	const apiConfig = new Configuration({
		basePath: process.env.REACT_APP_BASE_URL,
	});

	const authApi = new AuthApi(apiConfig);

	React.useEffect(() => {
		setIsLoading(true);
		setError(false);

		authApi
			.getForgotPasswordRequestData({ token })
			.catch((e) => {
				setError(true);
			})
			.finally(() => {
				setIsLoading(false);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const { addToast } = useToasts();
	const { login } = useAuth();

	const initialValues = {
		email: '',
		newPassword: '',
		confirmPassword: '',
	};

	const schema = yup.object().shape({
		email: yup.string().email('Formato de e-mail inválido').required('Campo obrigatório'),
		newPassword: yup.string().min(6, 'A senha deve conter pelo menos seis digitos.').required('Campo obrigatório'),
		confirmPassword: yup
			.string()
			.required('Campo obrigatório')
			.test('passwords-match', 'As senhas não coincidem', function (value) {
				return this.parent.newPassword === value;
			}),
	});

	const handleFormSubmit = async (values) => {
		setIsLoading(true);

		try {
			const resetPasswordRequestDto: ResetPasswordRequestDto = {
				email: values.email,
				token,
				newPassword: values.newPassword,
			};

			await authApi.resetPasswordRequest({ resetPasswordRequestDto });

			addToast('Senha alterada com sucesso', {
				appearance: 'success',
				autoDismiss: true,
			});
		} catch (e) {
			let errorMessage = await parseExceptionMessage(e, 'Não foi possível alterar a senha');

			if (/^Invalid request data$/.test(errorMessage)) {
				errorMessage = 'E-mail inválido';
			}
			addToast(errorMessage, {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setIsLoading(false);
		}

		try {
			setIsLoading(true);

			await login({
				email: values.email,
				password: values.newPassword,
			});

			history.push('/admin/dashboard');
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Flex width={`100%`} flexDirection={`row`} alignItems={`space-between`} justifyContent={`center`}>
			<Loader isOpen={isLoading} />
			<Stack w={`40%`} spacing={3} mt={20}>
				<Flex flexDir={`column`} alignItems={`center`}>
					<Image src={logoUrl()} w={130} />
				</Flex>
				<Flex flexDir={`column`}>
					{error ? (
						<Alert mt={10} status='error'>
							<AlertIcon />
							<Text id='invalid-link-text'>Link inválido</Text>
						</Alert>
					) : (
						<Formik enableReinitialize initialValues={initialValues} validationSchema={schema} onSubmit={handleFormSubmit}>
							{({ handleSubmit }) => {
								return (
									<form onSubmit={handleSubmit}>
										<Stack spacing={5}>
											<FormControl>
												<FormLabel id='forgot-password-email-label' htmlFor='email'>E-mail</FormLabel>
												<FormInput id='forgot-password-email-form-input' name='email' />
												<FormErrorMessage id='forgot-password-email-form-error-text' name='email' />
											</FormControl>
											<FormControl>
												<FormLabel id='new-password-label' htmlFor='newPassword'>Nova senha</FormLabel>
												<FormPasswordInput id='forgot-password-new-password-form-debounced-input' name='newPassword' />
												<FormErrorMessage id='forgot-password-new-password-form-error-text' name='newPassword' />
											</FormControl>
											<FormControl>
												<FormLabel id='confirm-password-label' htmlFor='confirmPassword'>Repita a nova senha</FormLabel>
												<FormPasswordInput id='forgot-password-confirm-password-form-debounced-input' name='confirmPassword' />
												<FormErrorMessage id='forgot-password-comfirm-password-form-error-text' name='confirmPassword' />
											</FormControl>
										</Stack>
										<Flex mt={5}>
											<Button id='change-password-button' width={`100%`} type='submit' isLoading={isLoading} px={6}>
												Alterar senha
											</Button>
										</Flex>
									</form>
								);
							}}
						</Formik>
					)}
				</Flex>
			</Stack>
		</Flex>
	);
};

export default ForgotPassword;
