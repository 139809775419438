/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CreateMetadataDto,
    CreateMetadataDtoFromJSON,
    CreateMetadataDtoFromJSONTyped,
    CreateMetadataDtoToJSON,
    CurrencyEnum,
    CurrencyEnumFromJSON,
    CurrencyEnumFromJSONTyped,
    CurrencyEnumToJSON,
    FeeTransactionDto,
    FeeTransactionDtoFromJSON,
    FeeTransactionDtoFromJSONTyped,
    FeeTransactionDtoToJSON,
    OperationEnum,
    OperationEnumFromJSON,
    OperationEnumFromJSONTyped,
    OperationEnumToJSON,
    PaymentMethodTransactionDto,
    PaymentMethodTransactionDtoFromJSON,
    PaymentMethodTransactionDtoFromJSONTyped,
    PaymentMethodTransactionDtoToJSON,
    SourceTransactionDto,
    SourceTransactionDtoFromJSON,
    SourceTransactionDtoFromJSONTyped,
    SourceTransactionDtoToJSON,
    StatusEnum,
    StatusEnumFromJSON,
    StatusEnumFromJSONTyped,
    StatusEnumToJSON,
} from './';

/**
 * 
 * @export
 * @interface TransactionDto
 */
export interface TransactionDto {
    /**
     * 
     * @type {string}
     * @memberof TransactionDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionDto
     */
    seller_id: string;
    /**
     * Número de controle para agrupar transações da mesma venda. ex.: compra + estorno/cancelamento.
     * @type {string}
     * @memberof TransactionDto
     */
    transaction_number: string;
    /**
     * 
     * @type {number}
     * @memberof TransactionDto
     */
    amount_without_fees: number;
    /**
     * 
     * @type {number}
     * @memberof TransactionDto
     */
    amount_with_fees: number;
    /**
     * 
     * @type {string}
     * @memberof TransactionDto
     */
    description: string;
    /**
     * 
     * @type {Array<FeeTransactionDto>}
     * @memberof TransactionDto
     */
    fees: Array<FeeTransactionDto>;
    /**
     * 
     * @type {PaymentMethodTransactionDto}
     * @memberof TransactionDto
     */
    payment_method: PaymentMethodTransactionDto;
    /**
     * 
     * @type {OperationEnum}
     * @memberof TransactionDto
     */
    operation_type: OperationEnum;
    /**
     * 
     * @type {CurrencyEnum}
     * @memberof TransactionDto
     */
    currency: CurrencyEnum;
    /**
     * 
     * @type {SourceTransactionDto}
     * @memberof TransactionDto
     */
    source: SourceTransactionDto;
    /**
     * 
     * @type {StatusEnum}
     * @memberof TransactionDto
     */
    status: StatusEnum;
    /**
     * 
     * @type {CreateMetadataDto}
     * @memberof TransactionDto
     */
    metadata: CreateMetadataDto;
    /**
     * 
     * @type {string}
     * @memberof TransactionDto
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionDto
     */
    updated_at: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionDto
     */
    accounting_account_id: string;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionDto
     */
    merchant_has_pre_cap: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionDto
     */
    pre_capture: boolean;
    /**
     * 
     * @type {number}
     * @memberof TransactionDto
     */
    installments: number;
    /**
     * 
     * @type {number}
     * @memberof TransactionDto
     */
    created_at_timestamp: number;
    /**
     * 
     * @type {number}
     * @memberof TransactionDto
     */
    order_number: number;
    /**
     * 
     * @type {string}
     * @memberof TransactionDto
     */
    seller_code: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionDto
     */
    card_brand: TransactionDtoCardBrandEnum;
    /**
     * 
     * @type {string}
     * @memberof TransactionDto
     */
    competence_date: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionDto
     */
    payment_type_id: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionDto
     */
    payment_type_code: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionDto
     */
    event_code: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionDto
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionDto
     */
    form_payment?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionDto
     */
    is_standalone?: boolean;
}

/**
* @export
* @enum {string}
*/
export enum TransactionDtoCardBrandEnum {
    Visa = 'visa',
    Elo = 'elo',
    Mc = 'mc',
    Amex = 'amex',
    Hipercard = 'hipercard'
}

export function TransactionDtoFromJSON(json: any): TransactionDto {
    return TransactionDtoFromJSONTyped(json, false);
}

export function TransactionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransactionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'seller_id': json['seller_id'],
        'transaction_number': json['transaction_number'],
        'amount_without_fees': json['amount_without_fees'],
        'amount_with_fees': json['amount_with_fees'],
        'description': json['description'],
        'fees': ((json['fees'] as Array<any>).map(FeeTransactionDtoFromJSON)),
        'payment_method': PaymentMethodTransactionDtoFromJSON(json['payment_method']),
        'operation_type': OperationEnumFromJSON(json['operation_type']),
        'currency': CurrencyEnumFromJSON(json['currency']),
        'source': SourceTransactionDtoFromJSON(json['source']),
        'status': StatusEnumFromJSON(json['status']),
        'metadata': CreateMetadataDtoFromJSON(json['metadata']),
        'created_at': json['created_at'],
        'updated_at': json['updated_at'],
        'accounting_account_id': json['accounting_account_id'],
        'merchant_has_pre_cap': json['merchant_has_pre_cap'],
        'pre_capture': json['pre_capture'],
        'installments': json['installments'],
        'created_at_timestamp': json['created_at_timestamp'],
        'order_number': json['order_number'],
        'seller_code': json['seller_code'],
        'card_brand': json['card_brand'],
        'competence_date': json['competence_date'],
        'payment_type_id': json['payment_type_id'],
        'payment_type_code': json['payment_type_code'],
        'event_code': json['event_code'],
        'type': json['type'],
        'form_payment': !exists(json, 'form_payment') ? undefined : json['form_payment'],
        'is_standalone': !exists(json, 'is_standalone') ? undefined : json['is_standalone'],
    };
}

export function TransactionDtoToJSON(value?: TransactionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'seller_id': value.seller_id,
        'transaction_number': value.transaction_number,
        'amount_without_fees': value.amount_without_fees,
        'amount_with_fees': value.amount_with_fees,
        'description': value.description,
        'fees': ((value.fees as Array<any>).map(FeeTransactionDtoToJSON)),
        'payment_method': PaymentMethodTransactionDtoToJSON(value.payment_method),
        'operation_type': OperationEnumToJSON(value.operation_type),
        'currency': CurrencyEnumToJSON(value.currency),
        'source': SourceTransactionDtoToJSON(value.source),
        'status': StatusEnumToJSON(value.status),
        'metadata': CreateMetadataDtoToJSON(value.metadata),
        'created_at': value.created_at,
        'updated_at': value.updated_at,
        'accounting_account_id': value.accounting_account_id,
        'merchant_has_pre_cap': value.merchant_has_pre_cap,
        'pre_capture': value.pre_capture,
        'installments': value.installments,
        'created_at_timestamp': value.created_at_timestamp,
        'order_number': value.order_number,
        'seller_code': value.seller_code,
        'card_brand': value.card_brand,
        'competence_date': value.competence_date,
        'payment_type_id': value.payment_type_id,
        'payment_type_code': value.payment_type_code,
        'event_code': value.event_code,
        'type': value.type,
        'form_payment': value.form_payment,
        'is_standalone': value.is_standalone,
    };
}


