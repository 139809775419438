import * as yup from 'yup';

import { cpf, cnpj } from 'cpf-cnpj-validator';

yup.addMethod(yup.string, 'isCpf', function (errorMessage) {
	return this.test(`is-cpf`, errorMessage, function (value) {  
		const { createError } = this;
			
    return (value && cpf.isValid(value)) || createError({ message: errorMessage });
  });
});

yup.addMethod(yup.string, 'isCnpj', function (errorMessage) {
  return this.test(`is-cnpj`, errorMessage, function (value) {
		const { createError } = this;

    return (value && cnpj.isValid(value)) || createError({ message: errorMessage });
  });
});

yup.addMethod(yup.string, 'isCpfOrCnpj', function (errorMessage) {
  return this.test(`is-cpf-or-cnpj`, errorMessage, function (value) {
		const { createError } = this;

    return (value && (cpf.isValid(value) || cnpj.isValid(value))) || createError({ message: errorMessage });
  });
});

yup.addMethod(yup.string, 'isPhone', function (errorMessage) {
  return this.test(`is-phone`, errorMessage, function (value) {
		const { createError } = this;

    return (value && value.replace(/\D+/gi, '').length >= 10) || createError({ message: errorMessage });
  });
});

yup.addMethod(yup.string, 'isCellphone', function (errorMessage) {
  return this.test(`is-phone`, errorMessage, function (value) {
		const { createError } = this;

    return (value && value.replace(/\D+/gi, '').length >= 11) || createError({ message: errorMessage });
  });
});

yup.addMethod(yup.string, 'isCpfOrCnpjOptional', function (errorMessage) {
	return this.test(`is-cpf-or-cnpj-optional`, errorMessage, function (value) {
		const { createError } = this;

		if (!value) {
			return true;
		}

		return (value && (cpf.isValid(value) || cnpj.isValid(value))) || createError({ message: errorMessage });
	});
});

yup.addMethod(yup.string, 'isCompleteName', function (errorMessage) {
  return this.test(`is-complete-name`, errorMessage, function (value) {
    const {createError} = this;

    const trimName = value?.trim();
		const name = trimName?.split(' ');
		const valid = name && name[1] ? true : false;
    return (value && valid) || createError({ message: errorMessage })
  })
})

yup.addMethod(yup.string, "isPixKey", function (errorMessage) {
  return this.test("is-pix-Key", errorMessage, function (value) {
		const {createError} = this;
    if (!value) return false;

    const cpfRegex = /^\d{11}$/;
    const cnpjRegex = /^\d{14}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\+55\d{10,11}$/;
    const uuidRegex =
      /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;

    const isValid =
      cpfRegex.test(value) ||
      cnpjRegex.test(value) ||
      emailRegex.test(value) ||
      phoneRegex.test(value) ||
      uuidRegex.test(value);

    return isValid || createError({ message: errorMessage });
  });
});
export default yup;