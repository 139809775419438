import yup from '../../../services/yup.service';

const preRegisterSellerSchema = yup.object().shape({
	name: yup.string().required('Campo obrigatório').max(48, 'A razão social deve conter até 48 caracteres'),
	cns: yup.string().required('Campo obrigatório'),
	document: yup.string().required('Campo obrigatório').isCpfOrCnpj('Deve ser informado um CPF ou CNPJ válido'),
	description: yup.string().required('Campo obrigatório').max(64, 'O nome fantasia deve conter até 64 caracteres'),
	address: yup.object().shape({
		zipcode: yup.string().required('Campo obrigatório'),
		street: yup.string().required('Campo obrigatório'),
		district: yup.string().required('Campo obrigatório'),
		city: yup.string().required('Campo obrigatório'),
		state: yup.string().required('Campo obrigatório'),
		complement: yup.string(),
		number: yup.string().required('Campo obrigatório'),
	}),
	phone: yup.string().required('Campo obrigatório'),
	secondary_phone: yup.string().required('Campo obrigatório'),
	tertiary_phone: yup.string(),
	email: yup.string().required('Campo obrigatório').email('Formato de e-mail inválido'),
	secondary_email: yup.string().email('Formato de e-mail inválido').required('Campo obrigatório'),
	tertiary_email: yup.string().email('Formato de e-mail inválido'),
	erp: yup.string(),
	seller_assignment: yup.string().required('Campo obrigatório'),
	owner_name: yup.string().required('Campo obrigatório'),
	owner_document: yup.string().required('Campo obrigatório'),
	owner_mother_name: yup.string().required('Campo obrigatório'),
	owner_birth_year: yup.string().required('Campo obrigatório'),
	owner_birth_month: yup.string().required('Campo obrigatório'),
	owner_birth_day: yup.string().required('Campo obrigatório'),
	bank_account: yup.object().shape({
		bank_code: yup.string().required('Campo obrigatório'),
		agency_number: yup.string().required('Campo obrigatório').typeError('O valor deve ser do tipo numérico'),
		agency_digit: yup.string().nullable().typeError('O valor deve ser do tipo numérico').max(1, 'Permitido apenas um dígito'),
		account_number: yup.string().required('Campo obrigatório').typeError('O valor deve ser do tipo numérico'),
		account_digit: yup.string().nullable().typeError('O valor deve ser do tipo numérico').max(1, 'Permitido apenas um dígito'),
		type_account: yup.string().required('Campo obrigatório'),
		pix_key: yup.string().nullable(),
	}),
	active_debit: yup.boolean().required('Campo obrigatório'),
	active_credit_1x: yup.boolean().required('Campo obrigatório'),
	active_credit_2x_to_18x: yup.boolean().required('Campo obrigatório'),
	active_billet: yup.boolean().required('Campo obrigatório'),
	active_pix_pos: yup.boolean().required('Campo obrigatório'),
	active_pix_online: yup.boolean().required('Campo obrigatório'),
	pass_on_cost_debit: yup.boolean().when('active_debit', {
		is: 'true',
		then: yup.boolean().required('Campo obrigatório quando vai utilizar débito'),
		otherwise: yup.boolean(),
	}),
	pass_on_cost_credit_1x: yup.boolean().when('active_credit_1x', {
		is: 'true',
		then: yup.boolean().required('Campo obrigatório quando vai utilizar crédito 1x'),
		otherwise: yup.boolean(),
	}),
	pass_on_cost_credit_2x_to_18x: yup.boolean().when('active_credit_2x_to_18x', {
		is: 'true',
		then: yup.boolean().required('Campo obrigatório quando vai utilizar créidito 2x a 18x'),
		otherwise: yup.boolean(),
	}),
	pass_on_cost_billet: yup.boolean().when('active_billet', {
		is: 'true',
		then: yup.boolean().required('Campo obrigatório quando vai utilizar boleto'),
		otherwise: yup.boolean(),
	}),
	pass_on_cost_pix_pos: yup.boolean().when('active_pix_pos', {
		is: 'true',
		then: yup.boolean().required('Campo obrigatório quando vai utilizar pix na máquina'),
		otherwise: yup.boolean(),
	}),
	pass_on_cost_pix_online: yup.boolean().when('active_pix_online', {
		is: 'true',
		then: yup.boolean().required('Campo obrigatório quando vai utilizar pix online'),
		otherwise: yup.boolean(),
	}),
	confirm_information: yup.boolean().required('Você precisa aceitar os termos antes de prosseguir')
});

export default preRegisterSellerSchema;
