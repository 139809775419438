/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CreateAttachmentDto,
    CreateAttachmentDtoFromJSON,
    CreateAttachmentDtoFromJSONTyped,
    CreateAttachmentDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface OpenDigitalAccountFileDto
 */
export interface OpenDigitalAccountFileDto {
    /**
     * 
     * @type {CreateAttachmentDto}
     * @memberof OpenDigitalAccountFileDto
     */
    attachment: CreateAttachmentDto;
    /**
     * 
     * @type {string}
     * @memberof OpenDigitalAccountFileDto
     */
    type: OpenDigitalAccountFileDtoTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum OpenDigitalAccountFileDtoTypeEnum {
    ContratoSocial = 'CONTRATO_SOCIAL',
    Procuracao = 'PROCURACAO',
    EstatutoSocial = 'ESTATUTO_SOCIAL',
    RequerimentoEmpresario = 'REQUERIMENTO_EMPRESARIO',
    BalancoDre = 'BALANCO_DRE',
    AtaEleicaoDiretoria = 'ATA_ELEICAO_DIRETORIA',
    QuadroSocietario = 'QUADRO_SOCIETARIO',
    DocumentoFinanceiro = 'DOCUMENTO_FINANCEIRO',
    Ccmei = 'CCMEI'
}

export function OpenDigitalAccountFileDtoFromJSON(json: any): OpenDigitalAccountFileDto {
    return OpenDigitalAccountFileDtoFromJSONTyped(json, false);
}

export function OpenDigitalAccountFileDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OpenDigitalAccountFileDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attachment': CreateAttachmentDtoFromJSON(json['attachment']),
        'type': json['type'],
    };
}

export function OpenDigitalAccountFileDtoToJSON(value?: OpenDigitalAccountFileDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attachment': CreateAttachmentDtoToJSON(value.attachment),
        'type': value.type,
    };
}


