/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetBilletDto,
    GetBilletDtoFromJSON,
    GetBilletDtoFromJSONTyped,
    GetBilletDtoToJSON,
    GetPaymentLinkDto,
    GetPaymentLinkDtoFromJSON,
    GetPaymentLinkDtoFromJSONTyped,
    GetPaymentLinkDtoToJSON,
    GetSalePayerAddressDto,
    GetSalePayerAddressDtoFromJSON,
    GetSalePayerAddressDtoFromJSONTyped,
    GetSalePayerAddressDtoToJSON,
    GetSalePayerDto,
    GetSalePayerDtoFromJSON,
    GetSalePayerDtoFromJSONTyped,
    GetSalePayerDtoToJSON,
    GetSalePlanPaymentTypeDto,
    GetSalePlanPaymentTypeDtoFromJSON,
    GetSalePlanPaymentTypeDtoFromJSONTyped,
    GetSalePlanPaymentTypeDtoToJSON,
    GetSaleSalesPlanDto,
    GetSaleSalesPlanDtoFromJSON,
    GetSaleSalesPlanDtoFromJSONTyped,
    GetSaleSalesPlanDtoToJSON,
    GetSaleSellerDto,
    GetSaleSellerDtoFromJSON,
    GetSaleSellerDtoFromJSONTyped,
    GetSaleSellerDtoToJSON,
    GetSaleUserDto,
    GetSaleUserDtoFromJSON,
    GetSaleUserDtoFromJSONTyped,
    GetSaleUserDtoToJSON,
    GetSplitRulesDto,
    GetSplitRulesDtoFromJSON,
    GetSplitRulesDtoFromJSONTyped,
    GetSplitRulesDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetSaleWithAcquirerDocumentDto
 */
export interface GetSaleWithAcquirerDocumentDto {
    /**
     * 
     * @type {string}
     * @memberof GetSaleWithAcquirerDocumentDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetSaleWithAcquirerDocumentDto
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof GetSaleWithAcquirerDocumentDto
     */
    protocol: string;
    /**
     * 
     * @type {string}
     * @memberof GetSaleWithAcquirerDocumentDto
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof GetSaleWithAcquirerDocumentDto
     */
    updated_at: string;
    /**
     * 
     * @type {number}
     * @memberof GetSaleWithAcquirerDocumentDto
     */
    original_amount: number;
    /**
     * 
     * @type {number}
     * @memberof GetSaleWithAcquirerDocumentDto
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof GetSaleWithAcquirerDocumentDto
     */
    type: GetSaleWithAcquirerDocumentDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof GetSaleWithAcquirerDocumentDto
     */
    formatted_type?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSaleWithAcquirerDocumentDto
     */
    form_payment: GetSaleWithAcquirerDocumentDtoFormPaymentEnum;
    /**
     * 
     * @type {string}
     * @memberof GetSaleWithAcquirerDocumentDto
     */
    status: GetSaleWithAcquirerDocumentDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof GetSaleWithAcquirerDocumentDto
     */
    settlement_day?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSaleWithAcquirerDocumentDto
     */
    settlement_status?: string;
    /**
     * 
     * @type {GetSaleSellerDto}
     * @memberof GetSaleWithAcquirerDocumentDto
     */
    seller: GetSaleSellerDto;
    /**
     * 
     * @type {GetSaleSalesPlanDto}
     * @memberof GetSaleWithAcquirerDocumentDto
     */
    sale_plan: GetSaleSalesPlanDto;
    /**
     * 
     * @type {GetSaleUserDto}
     * @memberof GetSaleWithAcquirerDocumentDto
     */
    user: GetSaleUserDto;
    /**
     * 
     * @type {GetSalePlanPaymentTypeDto}
     * @memberof GetSaleWithAcquirerDocumentDto
     */
    payment_type: GetSalePlanPaymentTypeDto;
    /**
     * 
     * @type {GetSalePayerDto}
     * @memberof GetSaleWithAcquirerDocumentDto
     */
    payer: GetSalePayerDto;
    /**
     * 
     * @type {GetBilletDto}
     * @memberof GetSaleWithAcquirerDocumentDto
     */
    billet: GetBilletDto;
    /**
     * 
     * @type {string}
     * @memberof GetSaleWithAcquirerDocumentDto
     */
    billet_id: string;
    /**
     * 
     * @type {GetPaymentLinkDto}
     * @memberof GetSaleWithAcquirerDocumentDto
     */
    payment_link: GetPaymentLinkDto;
    /**
     * 
     * @type {GetSplitRulesDto}
     * @memberof GetSaleWithAcquirerDocumentDto
     */
    split_rules: GetSplitRulesDto;
    /**
     * 
     * @type {boolean}
     * @memberof GetSaleWithAcquirerDocumentDto
     */
    has_split_rules?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetSaleWithAcquirerDocumentDto
     */
    formatted_description?: string;
    /**
     * 
     * @type {number}
     * @memberof GetSaleWithAcquirerDocumentDto
     */
    installments: number;
    /**
     * 
     * @type {string}
     * @memberof GetSaleWithAcquirerDocumentDto
     */
    seller_name: string;
    /**
     * 
     * @type {string}
     * @memberof GetSaleWithAcquirerDocumentDto
     */
    seller_document: string;
    /**
     * 
     * @type {string}
     * @memberof GetSaleWithAcquirerDocumentDto
     */
    seller_description: string;
    /**
     * 
     * @type {string}
     * @memberof GetSaleWithAcquirerDocumentDto
     */
    payment_type_code: string;
    /**
     * 
     * @type {string}
     * @memberof GetSaleWithAcquirerDocumentDto
     */
    payment_type_description: string;
    /**
     * 
     * @type {number}
     * @memberof GetSaleWithAcquirerDocumentDto
     */
    payment_type_installments: number;
    /**
     * 
     * @type {string}
     * @memberof GetSaleWithAcquirerDocumentDto
     */
    payer_name: string;
    /**
     * 
     * @type {string}
     * @memberof GetSaleWithAcquirerDocumentDto
     */
    payer_email: string;
    /**
     * 
     * @type {string}
     * @memberof GetSaleWithAcquirerDocumentDto
     */
    payer_document: string;
    /**
     * 
     * @type {string}
     * @memberof GetSaleWithAcquirerDocumentDto
     */
    payer_phone: string;
    /**
     * 
     * @type {GetSalePayerAddressDto}
     * @memberof GetSaleWithAcquirerDocumentDto
     */
    payer_address: GetSalePayerAddressDto;
    /**
     * 
     * @type {string}
     * @memberof GetSaleWithAcquirerDocumentDto
     */
    sale_plan_name: string;
    /**
     * 
     * @type {string}
     * @memberof GetSaleWithAcquirerDocumentDto
     */
    created_by_user_name: string;
    /**
     * 
     * @type {string}
     * @memberof GetSaleWithAcquirerDocumentDto
     */
    created_by_user_email: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetSaleWithAcquirerDocumentDto
     */
    pre_capture: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetSaleWithAcquirerDocumentDto
     */
    settlement_day_cancellation?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSaleWithAcquirerDocumentDto
     */
    settlement_status_cancellation?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSaleWithAcquirerDocumentDto
     */
    payment_day?: string;
    /**
     * 
     * @type {number}
     * @memberof GetSaleWithAcquirerDocumentDto
     */
    fee?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetSaleWithAcquirerDocumentDto
     */
    absorb_costs?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof GetSaleWithAcquirerDocumentDto
     */
    order_number: number;
    /**
     * 
     * @type {object}
     * @memberof GetSaleWithAcquirerDocumentDto
     */
    metadata: object;
    /**
     * 
     * @type {string}
     * @memberof GetSaleWithAcquirerDocumentDto
     */
    card_number?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSaleWithAcquirerDocumentDto
     */
    card_brand?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSaleWithAcquirerDocumentDto
     */
    card_holder_name?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSaleWithAcquirerDocumentDto
     */
    acquirer_code?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSaleWithAcquirerDocumentDto
     */
    acquirer_document?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetSaleWithAcquirerDocumentDto
     */
    confirmation_required?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetSaleWithAcquirerDocumentDto
     */
    extract_identification?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSaleWithAcquirerDocumentDto
     */
    confirmation_day?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSaleWithAcquirerDocumentDto
     */
    service_id?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSaleWithAcquirerDocumentDto
     */
    split_description?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSaleWithAcquirerDocumentDto
     */
    formatted_split_description?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSaleWithAcquirerDocumentDto
     */
    cnab_id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetSaleWithAcquirerDocumentDto
     */
    generate_payer?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetSaleWithAcquirerDocumentDto
     */
    is_main_sale?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetSaleWithAcquirerDocumentDto
     */
    acquirer_reference?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSaleWithAcquirerDocumentDto
     */
    merchant_id?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSaleWithAcquirerDocumentDto
     */
    merchant_name?: string;
}

/**
* @export
* @enum {string}
*/
export enum GetSaleWithAcquirerDocumentDtoTypeEnum {
    Boleto = 'boleto',
    Pos = 'pos',
    PaymentLink = 'payment_link',
    ProtestLink = 'protest_link',
    Online = 'online',
    AutoGeneratePaymentLink = 'auto_generate_payment_link',
    AutoGenerateProtestLink = 'auto_generate_protest_link',
    BilletCode = 'billet_code',
    BillPayment = 'bill_payment',
    Digital = 'digital'
}/**
* @export
* @enum {string}
*/
export enum GetSaleWithAcquirerDocumentDtoFormPaymentEnum {
    Credit = 'credit',
    Debit = 'debit',
    Billet = 'billet',
    Pix = 'pix',
    Pending = 'pending',
    PixPos = 'pix_pos'
}/**
* @export
* @enum {string}
*/
export enum GetSaleWithAcquirerDocumentDtoStatusEnum {
    Pending = 'pending',
    PendingCapture = 'pending_capture',
    InProgressCapture = 'in_progress_capture',
    Succeeded = 'succeeded',
    Canceled = 'canceled',
    PendingCancel = 'pending_cancel',
    Abort = 'abort',
    Failed = 'failed',
    WaitingConfirmation = 'waiting_confirmation',
    Expired = 'expired',
    WaitingCashIn = 'waiting_cashIn',
    Refused = 'refused'
}

export function GetSaleWithAcquirerDocumentDtoFromJSON(json: any): GetSaleWithAcquirerDocumentDto {
    return GetSaleWithAcquirerDocumentDtoFromJSONTyped(json, false);
}

export function GetSaleWithAcquirerDocumentDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetSaleWithAcquirerDocumentDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'description': json['description'],
        'protocol': json['protocol'],
        'created_at': json['created_at'],
        'updated_at': json['updated_at'],
        'original_amount': json['original_amount'],
        'amount': json['amount'],
        'type': json['type'],
        'formatted_type': !exists(json, 'formatted_type') ? undefined : json['formatted_type'],
        'form_payment': json['form_payment'],
        'status': json['status'],
        'settlement_day': !exists(json, 'settlement_day') ? undefined : json['settlement_day'],
        'settlement_status': !exists(json, 'settlement_status') ? undefined : json['settlement_status'],
        'seller': GetSaleSellerDtoFromJSON(json['seller']),
        'sale_plan': GetSaleSalesPlanDtoFromJSON(json['sale_plan']),
        'user': GetSaleUserDtoFromJSON(json['user']),
        'payment_type': GetSalePlanPaymentTypeDtoFromJSON(json['payment_type']),
        'payer': GetSalePayerDtoFromJSON(json['payer']),
        'billet': GetBilletDtoFromJSON(json['billet']),
        'billet_id': json['billet_id'],
        'payment_link': GetPaymentLinkDtoFromJSON(json['payment_link']),
        'split_rules': GetSplitRulesDtoFromJSON(json['split_rules']),
        'has_split_rules': !exists(json, 'has_split_rules') ? undefined : json['has_split_rules'],
        'formatted_description': !exists(json, 'formatted_description') ? undefined : json['formatted_description'],
        'installments': json['installments'],
        'seller_name': json['seller_name'],
        'seller_document': json['seller_document'],
        'seller_description': json['seller_description'],
        'payment_type_code': json['payment_type_code'],
        'payment_type_description': json['payment_type_description'],
        'payment_type_installments': json['payment_type_installments'],
        'payer_name': json['payer_name'],
        'payer_email': json['payer_email'],
        'payer_document': json['payer_document'],
        'payer_phone': json['payer_phone'],
        'payer_address': GetSalePayerAddressDtoFromJSON(json['payer_address']),
        'sale_plan_name': json['sale_plan_name'],
        'created_by_user_name': json['created_by_user_name'],
        'created_by_user_email': json['created_by_user_email'],
        'pre_capture': json['pre_capture'],
        'settlement_day_cancellation': !exists(json, 'settlement_day_cancellation') ? undefined : json['settlement_day_cancellation'],
        'settlement_status_cancellation': !exists(json, 'settlement_status_cancellation') ? undefined : json['settlement_status_cancellation'],
        'payment_day': !exists(json, 'payment_day') ? undefined : json['payment_day'],
        'fee': !exists(json, 'fee') ? undefined : json['fee'],
        'absorb_costs': !exists(json, 'absorb_costs') ? undefined : json['absorb_costs'],
        'order_number': json['order_number'],
        'metadata': json['metadata'],
        'card_number': !exists(json, 'card_number') ? undefined : json['card_number'],
        'card_brand': !exists(json, 'card_brand') ? undefined : json['card_brand'],
        'card_holder_name': !exists(json, 'card_holder_name') ? undefined : json['card_holder_name'],
        'acquirer_code': !exists(json, 'acquirer_code') ? undefined : json['acquirer_code'],
        'acquirer_document': !exists(json, 'acquirer_document') ? undefined : json['acquirer_document'],
        'confirmation_required': !exists(json, 'confirmation_required') ? undefined : json['confirmation_required'],
        'extract_identification': !exists(json, 'extract_identification') ? undefined : json['extract_identification'],
        'confirmation_day': !exists(json, 'confirmation_day') ? undefined : json['confirmation_day'],
        'service_id': !exists(json, 'service_id') ? undefined : json['service_id'],
        'split_description': !exists(json, 'split_description') ? undefined : json['split_description'],
        'formatted_split_description': !exists(json, 'formatted_split_description') ? undefined : json['formatted_split_description'],
        'cnab_id': !exists(json, 'cnab_id') ? undefined : json['cnab_id'],
        'generate_payer': !exists(json, 'generate_payer') ? undefined : json['generate_payer'],
        'is_main_sale': !exists(json, 'is_main_sale') ? undefined : json['is_main_sale'],
        'acquirer_reference': !exists(json, 'acquirer_reference') ? undefined : json['acquirer_reference'],
        'merchant_id': !exists(json, 'merchant_id') ? undefined : json['merchant_id'],
        'merchant_name': !exists(json, 'merchant_name') ? undefined : json['merchant_name'],
    };
}

export function GetSaleWithAcquirerDocumentDtoToJSON(value?: GetSaleWithAcquirerDocumentDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'description': value.description,
        'protocol': value.protocol,
        'created_at': value.created_at,
        'updated_at': value.updated_at,
        'original_amount': value.original_amount,
        'amount': value.amount,
        'type': value.type,
        'formatted_type': value.formatted_type,
        'form_payment': value.form_payment,
        'status': value.status,
        'settlement_day': value.settlement_day,
        'settlement_status': value.settlement_status,
        'seller': GetSaleSellerDtoToJSON(value.seller),
        'sale_plan': GetSaleSalesPlanDtoToJSON(value.sale_plan),
        'user': GetSaleUserDtoToJSON(value.user),
        'payment_type': GetSalePlanPaymentTypeDtoToJSON(value.payment_type),
        'payer': GetSalePayerDtoToJSON(value.payer),
        'billet': GetBilletDtoToJSON(value.billet),
        'billet_id': value.billet_id,
        'payment_link': GetPaymentLinkDtoToJSON(value.payment_link),
        'split_rules': GetSplitRulesDtoToJSON(value.split_rules),
        'has_split_rules': value.has_split_rules,
        'formatted_description': value.formatted_description,
        'installments': value.installments,
        'seller_name': value.seller_name,
        'seller_document': value.seller_document,
        'seller_description': value.seller_description,
        'payment_type_code': value.payment_type_code,
        'payment_type_description': value.payment_type_description,
        'payment_type_installments': value.payment_type_installments,
        'payer_name': value.payer_name,
        'payer_email': value.payer_email,
        'payer_document': value.payer_document,
        'payer_phone': value.payer_phone,
        'payer_address': GetSalePayerAddressDtoToJSON(value.payer_address),
        'sale_plan_name': value.sale_plan_name,
        'created_by_user_name': value.created_by_user_name,
        'created_by_user_email': value.created_by_user_email,
        'pre_capture': value.pre_capture,
        'settlement_day_cancellation': value.settlement_day_cancellation,
        'settlement_status_cancellation': value.settlement_status_cancellation,
        'payment_day': value.payment_day,
        'fee': value.fee,
        'absorb_costs': value.absorb_costs,
        'order_number': value.order_number,
        'metadata': value.metadata,
        'card_number': value.card_number,
        'card_brand': value.card_brand,
        'card_holder_name': value.card_holder_name,
        'acquirer_code': value.acquirer_code,
        'acquirer_document': value.acquirer_document,
        'confirmation_required': value.confirmation_required,
        'extract_identification': value.extract_identification,
        'confirmation_day': value.confirmation_day,
        'service_id': value.service_id,
        'split_description': value.split_description,
        'formatted_split_description': value.formatted_split_description,
        'cnab_id': value.cnab_id,
        'generate_payer': value.generate_payer,
        'is_main_sale': value.is_main_sale,
        'acquirer_reference': value.acquirer_reference,
        'merchant_id': value.merchant_id,
        'merchant_name': value.merchant_name,
    };
}


