import React from 'react';

import Text from '../../../components/Text';
import Title from '../../../components/Title';
import NewModal from '../../../containers/NewModal';

type TermsModalTypes = {
	isOpen: boolean;
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const TermsModal: React.FC<TermsModalTypes> = ({ isOpen, setIsOpen }) => {
	return (
		<NewModal isOpen={isOpen} onClose={() => setIsOpen(false)} showCloseButton>
			<Title id='pre-register-modal-terms-title' m='5'>LOREM IPSU</Title>
			<Text id='pre-register-modal-terms-text' m='5'>
				Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas rhoncus lacinia lectus, sodales dignissim tortor ultricies at.
				Praesent iaculis dolor ac felis consectetur, ac dictum magna tempus. Etiam non auctor eros. Mauris ex metus, ultricies venenatis
				vestibulum ac, eleifend ut ipsum. Fusce ornare tortor ac scelerisque euismod. Praesent ac posuere lacus. Curabitur ac ex vitae justo
				congue hendrerit a nec urna. Ut egestas lectus et ante sollicitudin, viverra lacinia massa pretium. Praesent nibh erat, rutrum sed
				purus eget, consequat vulputate diam. Aliquam sed lorem metus. Aliquam erat volutpat. Curabitur eu mi nec dui tempus aliquet. Nunc
				pulvinar lacus a mi gravida malesuada. Duis faucibus est ex, ac pretium justo maximus nec. Pellentesque euismod tincidunt ipsum eget
				euismod. Nunc ut neque nec elit dictum consectetur. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia
				curae; Nam suscipit porttitor quam nec dignissim. Pellentesque ut ornare neque. Donec gravida ultrices lacinia. Donec tempor eu
				ipsum eget interdum. Proin pellentesque ante ac lorem tincidunt, vitae accumsan nisi rutrum. Vestibulum sit amet vulputate turpis.
				Nam interdum magna a quam tempor, quis sagittis velit hendrerit. Integer nulla arcu, suscipit in aliquam id, eleifend et nunc. Nunc
				a blandit justo, quis sodales nibh. Nam lobortis dolor augue, elementum elementum diam bibendum ut. Nullam ut leo dignissim,
				ultrices est tincidunt, vulputate ante. Curabitur a tellus nec nisl ornare ultricies at eget diam. Curabitur convallis augue nulla,
				in pellentesque eros convallis nec. Donec lacinia sem libero, in condimentum enim congue quis. Mauris sed ligula magna. Donec tellus
				leo, blandit et tellus vehicula, lacinia consectetur risus. In sed leo eget est pulvinar blandit. Quisque dapibus ac eros ac
				iaculis. Duis non ullamcorper augue. Duis at faucibus sem. Praesent maximus aliquet massa. Duis at quam et felis bibendum tempor
				quis sit amet neque. Mauris vulputate elementum mi, ac suscipit ex vestibulum eu. Cras risus lectus, mattis commodo ante eu, laoreet
				pulvinar mi. Fusce sed eros nec ante bibendum rutrum id non eros. Nullam iaculis ipsum suscipit, eleifend purus eget, maximus
				tellus. Ut quam orci, vestibulum at vulputate sit amet, varius sed neque. Nulla mollis, elit ut laoreet ullamcorper, ante nulla
				mattis nisi, quis vestibulum neque leo sed dui. Sed augue sapien, sodales eget viverra sed, accumsan ac eros. Proin dapibus elit
				est. Fusce eleifend diam quis aliquam sodales. Proin fermentum erat eget dui ultrices imperdiet. Maecenas non fringilla felis, vitae
				varius sem. Curabitur feugiat in turpis sit amet tristique. Aliquam a orci ac mi rhoncus rhoncus commodo ut justo. Curabitur in enim
				sem. Sed dignissim id lacus quis maximus.
			</Text>
		</NewModal>
	);
};

export default TermsModal;
