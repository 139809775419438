/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OpenDigitalAccountFileDto,
    OpenDigitalAccountFileDtoFromJSON,
    OpenDigitalAccountFileDtoFromJSONTyped,
    OpenDigitalAccountFileDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface OpenDigitalAccountDto
 */
export interface OpenDigitalAccountDto {
    /**
     * 
     * @type {string}
     * @memberof OpenDigitalAccountDto
     */
    sellerId: string;
    /**
     * 
     * @type {string}
     * @memberof OpenDigitalAccountDto
     */
    legalNature: OpenDigitalAccountDtoLegalNatureEnum;
    /**
     * 
     * @type {number}
     * @memberof OpenDigitalAccountDto
     */
    patrimonyAmount: number;
    /**
     * 
     * @type {string}
     * @memberof OpenDigitalAccountDto
     */
    document: string;
    /**
     * 
     * @type {string}
     * @memberof OpenDigitalAccountDto
     */
    zipcode: string;
    /**
     * 
     * @type {string}
     * @memberof OpenDigitalAccountDto
     */
    type: OpenDigitalAccountDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof OpenDigitalAccountDto
     */
    state: string;
    /**
     * 
     * @type {string}
     * @memberof OpenDigitalAccountDto
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof OpenDigitalAccountDto
     */
    street: string;
    /**
     * 
     * @type {string}
     * @memberof OpenDigitalAccountDto
     */
    number: string;
    /**
     * 
     * @type {string}
     * @memberof OpenDigitalAccountDto
     */
    complement?: string;
    /**
     * 
     * @type {string}
     * @memberof OpenDigitalAccountDto
     */
    district: string;
    /**
     * 
     * @type {Array<OpenDigitalAccountFileDto>}
     * @memberof OpenDigitalAccountDto
     */
    files: Array<OpenDigitalAccountFileDto>;
}

/**
* @export
* @enum {string}
*/
export enum OpenDigitalAccountDtoLegalNatureEnum {
    Mei = 'MEI',
    Ei = 'EI',
    Eireli = 'EIRELI',
    SociedadeAnonima = 'SOCIEDADE_ANONIMA',
    SociedadeSimplesLimitada = 'SOCIEDADE_SIMPLES_LIMITADA',
    Slu = 'SLU'
}/**
* @export
* @enum {string}
*/
export enum OpenDigitalAccountDtoTypeEnum {
    Residencial = 'RESIDENCIAL',
    Comercial = 'COMERCIAL',
    Outros = 'OUTROS',
    Naturalidade = 'NATURALIDADE'
}

export function OpenDigitalAccountDtoFromJSON(json: any): OpenDigitalAccountDto {
    return OpenDigitalAccountDtoFromJSONTyped(json, false);
}

export function OpenDigitalAccountDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OpenDigitalAccountDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sellerId': json['sellerId'],
        'legalNature': json['legalNature'],
        'patrimonyAmount': json['patrimonyAmount'],
        'document': json['document'],
        'zipcode': json['zipcode'],
        'type': json['type'],
        'state': json['state'],
        'city': json['city'],
        'street': json['street'],
        'number': json['number'],
        'complement': !exists(json, 'complement') ? undefined : json['complement'],
        'district': json['district'],
        'files': ((json['files'] as Array<any>).map(OpenDigitalAccountFileDtoFromJSON)),
    };
}

export function OpenDigitalAccountDtoToJSON(value?: OpenDigitalAccountDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sellerId': value.sellerId,
        'legalNature': value.legalNature,
        'patrimonyAmount': value.patrimonyAmount,
        'document': value.document,
        'zipcode': value.zipcode,
        'type': value.type,
        'state': value.state,
        'city': value.city,
        'street': value.street,
        'number': value.number,
        'complement': value.complement,
        'district': value.district,
        'files': ((value.files as Array<any>).map(OpenDigitalAccountFileDtoToJSON)),
    };
}


