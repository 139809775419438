/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DigitalAccountUserDataDto
 */
export interface DigitalAccountUserDataDto {
    /**
     * 
     * @type {string}
     * @memberof DigitalAccountUserDataDto
     */
    cpf: string;
    /**
     * 
     * @type {string}
     * @memberof DigitalAccountUserDataDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof DigitalAccountUserDataDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof DigitalAccountUserDataDto
     */
    phone_number: string;
}

export function DigitalAccountUserDataDtoFromJSON(json: any): DigitalAccountUserDataDto {
    return DigitalAccountUserDataDtoFromJSONTyped(json, false);
}

export function DigitalAccountUserDataDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DigitalAccountUserDataDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cpf': json['cpf'],
        'name': json['name'],
        'email': json['email'],
        'phone_number': json['phone_number'],
    };
}

export function DigitalAccountUserDataDtoToJSON(value?: DigitalAccountUserDataDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cpf': value.cpf,
        'name': value.name,
        'email': value.email,
        'phone_number': value.phone_number,
    };
}


