/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreatePaymentLinkSaleResponseDto
 */
export interface CreatePaymentLinkSaleResponseDto {
    /**
     * 
     * @type {string}
     * @memberof CreatePaymentLinkSaleResponseDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePaymentLinkSaleResponseDto
     */
    description: string;
    /**
     * 
     * @type {number}
     * @memberof CreatePaymentLinkSaleResponseDto
     */
    original_amount: number;
    /**
     * 
     * @type {string}
     * @memberof CreatePaymentLinkSaleResponseDto
     */
    type: CreatePaymentLinkSaleResponseDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreatePaymentLinkSaleResponseDto
     */
    status: CreatePaymentLinkSaleResponseDtoStatusEnum;
}

/**
* @export
* @enum {string}
*/
export enum CreatePaymentLinkSaleResponseDtoTypeEnum {
    Boleto = 'boleto',
    Pos = 'pos',
    PaymentLink = 'payment_link',
    ProtestLink = 'protest_link',
    Online = 'online',
    AutoGeneratePaymentLink = 'auto_generate_payment_link',
    AutoGenerateProtestLink = 'auto_generate_protest_link',
    BilletCode = 'billet_code',
    BillPayment = 'bill_payment',
    Digital = 'digital'
}/**
* @export
* @enum {string}
*/
export enum CreatePaymentLinkSaleResponseDtoStatusEnum {
    Pending = 'pending',
    PendingCapture = 'pending_capture',
    InProgressCapture = 'in_progress_capture',
    Succeeded = 'succeeded',
    Canceled = 'canceled',
    PendingCancel = 'pending_cancel',
    Abort = 'abort',
    Failed = 'failed',
    WaitingConfirmation = 'waiting_confirmation',
    Expired = 'expired',
    WaitingCashIn = 'waiting_cashIn',
    Refused = 'refused'
}

export function CreatePaymentLinkSaleResponseDtoFromJSON(json: any): CreatePaymentLinkSaleResponseDto {
    return CreatePaymentLinkSaleResponseDtoFromJSONTyped(json, false);
}

export function CreatePaymentLinkSaleResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreatePaymentLinkSaleResponseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'description': json['description'],
        'original_amount': json['original_amount'],
        'type': json['type'],
        'status': json['status'],
    };
}

export function CreatePaymentLinkSaleResponseDtoToJSON(value?: CreatePaymentLinkSaleResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'description': value.description,
        'original_amount': value.original_amount,
        'type': value.type,
        'status': value.status,
    };
}


