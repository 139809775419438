/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreateDigitalAuthenticationDto,
    CreateDigitalAuthenticationDtoFromJSON,
    CreateDigitalAuthenticationDtoToJSON,
    CreateDigitalAuthorizationDto,
    CreateDigitalAuthorizationDtoFromJSON,
    CreateDigitalAuthorizationDtoToJSON,
    DigitalAccountUserDataDto,
    DigitalAccountUserDataDtoFromJSON,
    DigitalAccountUserDataDtoToJSON,
    GetDigitalAuthorizationDto,
    GetDigitalAuthorizationDtoFromJSON,
    GetDigitalAuthorizationDtoToJSON,
    GetDigitalCredentialsDto,
    GetDigitalCredentialsDtoFromJSON,
    GetDigitalCredentialsDtoToJSON,
    GetPartnerEnterpriseAddressInformationResponseDto,
    GetPartnerEnterpriseAddressInformationResponseDtoFromJSON,
    GetPartnerEnterpriseAddressInformationResponseDtoToJSON,
    GetPartnerEnterpriseBasicInformationResponseDto,
    GetPartnerEnterpriseBasicInformationResponseDtoFromJSON,
    GetPartnerEnterpriseBasicInformationResponseDtoToJSON,
    GetPartnerEnterpriseDocumentsResponseDto,
    GetPartnerEnterpriseDocumentsResponseDtoFromJSON,
    GetPartnerEnterpriseDocumentsResponseDtoToJSON,
    GetPartnerEnterpriseLegalInformationResponseDto,
    GetPartnerEnterpriseLegalInformationResponseDtoFromJSON,
    GetPartnerEnterpriseLegalInformationResponseDtoToJSON,
    OpenDigitalAccountDto,
    OpenDigitalAccountDtoFromJSON,
    OpenDigitalAccountDtoToJSON,
} from '../models';

export interface DigitalAuthenticationRequest {
    createDigitalAuthenticationDto: CreateDigitalAuthenticationDto;
}

export interface DigitalAuthorizationRequest {
    createDigitalAuthorizationDto: CreateDigitalAuthorizationDto;
}

export interface GetDigitalCredentialsRequest {
    document: string;
}

export interface GetPartnerEnterpriseAddressInformationRequest {
    clientId: string;
    taxId: string;
}

export interface GetPartnerEnterpriseBasicInformationRequest {
    clientId: string;
    taxId: string;
}

export interface GetPartnerEnterpriseDocumentsRequest {
    clientId: string;
    taxId: string;
}

export interface GetPartnerEnterpriseLegalInformationRequest {
    clientId: string;
    taxId: string;
}

export interface OpenDigitalAccountRequest {
    openDigitalAccountDto: OpenDigitalAccountDto;
}

export interface RemoveDigitalCredentialsRequest {
    document: string;
}

export interface SsoRequest {
    sellerId: string;
    digitalAccountUserDataDto: DigitalAccountUserDataDto;
}

/**
 * 
 */
export class DigitalAccountApi extends runtime.BaseAPI {

    /**
     * Gera token de acesso digital
     */
    async digitalAuthenticationRaw(requestParameters: DigitalAuthenticationRequest): Promise<runtime.ApiResponse<GetDigitalCredentialsDto>> {
        if (requestParameters.createDigitalAuthenticationDto === null || requestParameters.createDigitalAuthenticationDto === undefined) {
            throw new runtime.RequiredError('createDigitalAuthenticationDto','Required parameter requestParameters.createDigitalAuthenticationDto was null or undefined when calling digitalAuthentication.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/digital-account/digital-authentication`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateDigitalAuthenticationDtoToJSON(requestParameters.createDigitalAuthenticationDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetDigitalCredentialsDtoFromJSON(jsonValue));
    }

    /**
     * Gera token de acesso digital
     */
    async digitalAuthentication(requestParameters: DigitalAuthenticationRequest): Promise<GetDigitalCredentialsDto> {
        const response = await this.digitalAuthenticationRaw(requestParameters);
        return await response.value();
    }

    /**
     * Redireciona para a autorização digital
     */
    async digitalAuthorizationRaw(requestParameters: DigitalAuthorizationRequest): Promise<runtime.ApiResponse<GetDigitalAuthorizationDto>> {
        if (requestParameters.createDigitalAuthorizationDto === null || requestParameters.createDigitalAuthorizationDto === undefined) {
            throw new runtime.RequiredError('createDigitalAuthorizationDto','Required parameter requestParameters.createDigitalAuthorizationDto was null or undefined when calling digitalAuthorization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/digital-account/digital-authorization`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateDigitalAuthorizationDtoToJSON(requestParameters.createDigitalAuthorizationDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetDigitalAuthorizationDtoFromJSON(jsonValue));
    }

    /**
     * Redireciona para a autorização digital
     */
    async digitalAuthorization(requestParameters: DigitalAuthorizationRequest): Promise<GetDigitalAuthorizationDto> {
        const response = await this.digitalAuthorizationRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retorna credenciais de acesso
     */
    async getDigitalCredentialsRaw(requestParameters: GetDigitalCredentialsRequest): Promise<runtime.ApiResponse<GetDigitalCredentialsDto>> {
        if (requestParameters.document === null || requestParameters.document === undefined) {
            throw new runtime.RequiredError('document','Required parameter requestParameters.document was null or undefined when calling getDigitalCredentials.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/digital-account/digital-credentials/{document}`.replace(`{${"document"}}`, encodeURIComponent(String(requestParameters.document))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetDigitalCredentialsDtoFromJSON(jsonValue));
    }

    /**
     * Retorna credenciais de acesso
     */
    async getDigitalCredentials(requestParameters: GetDigitalCredentialsRequest): Promise<GetDigitalCredentialsDto> {
        const response = await this.getDigitalCredentialsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retornar informações de endereço da empresa parceira
     */
    async getPartnerEnterpriseAddressInformationRaw(requestParameters: GetPartnerEnterpriseAddressInformationRequest): Promise<runtime.ApiResponse<GetPartnerEnterpriseAddressInformationResponseDto>> {
        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling getPartnerEnterpriseAddressInformation.');
        }

        if (requestParameters.taxId === null || requestParameters.taxId === undefined) {
            throw new runtime.RequiredError('taxId','Required parameter requestParameters.taxId was null or undefined when calling getPartnerEnterpriseAddressInformation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/digital-account/apps/{clientId}/.well-known/customers/{taxId}/address`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters.clientId))).replace(`{${"taxId"}}`, encodeURIComponent(String(requestParameters.taxId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPartnerEnterpriseAddressInformationResponseDtoFromJSON(jsonValue));
    }

    /**
     * Retornar informações de endereço da empresa parceira
     */
    async getPartnerEnterpriseAddressInformation(requestParameters: GetPartnerEnterpriseAddressInformationRequest): Promise<GetPartnerEnterpriseAddressInformationResponseDto> {
        const response = await this.getPartnerEnterpriseAddressInformationRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retornar informações básicas de empresa parceira
     */
    async getPartnerEnterpriseBasicInformationRaw(requestParameters: GetPartnerEnterpriseBasicInformationRequest): Promise<runtime.ApiResponse<GetPartnerEnterpriseBasicInformationResponseDto>> {
        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling getPartnerEnterpriseBasicInformation.');
        }

        if (requestParameters.taxId === null || requestParameters.taxId === undefined) {
            throw new runtime.RequiredError('taxId','Required parameter requestParameters.taxId was null or undefined when calling getPartnerEnterpriseBasicInformation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/digital-account/apps/{clientId}/.well-known/customers/{taxId}`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters.clientId))).replace(`{${"taxId"}}`, encodeURIComponent(String(requestParameters.taxId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPartnerEnterpriseBasicInformationResponseDtoFromJSON(jsonValue));
    }

    /**
     * Retornar informações básicas de empresa parceira
     */
    async getPartnerEnterpriseBasicInformation(requestParameters: GetPartnerEnterpriseBasicInformationRequest): Promise<GetPartnerEnterpriseBasicInformationResponseDto> {
        const response = await this.getPartnerEnterpriseBasicInformationRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retornar documentos da empresa parceira
     */
    async getPartnerEnterpriseDocumentsRaw(requestParameters: GetPartnerEnterpriseDocumentsRequest): Promise<runtime.ApiResponse<GetPartnerEnterpriseDocumentsResponseDto>> {
        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling getPartnerEnterpriseDocuments.');
        }

        if (requestParameters.taxId === null || requestParameters.taxId === undefined) {
            throw new runtime.RequiredError('taxId','Required parameter requestParameters.taxId was null or undefined when calling getPartnerEnterpriseDocuments.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/digital-account/apps/{clientId}/.well-known/customers/{taxId}/documents`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters.clientId))).replace(`{${"taxId"}}`, encodeURIComponent(String(requestParameters.taxId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPartnerEnterpriseDocumentsResponseDtoFromJSON(jsonValue));
    }

    /**
     * Retornar documentos da empresa parceira
     */
    async getPartnerEnterpriseDocuments(requestParameters: GetPartnerEnterpriseDocumentsRequest): Promise<GetPartnerEnterpriseDocumentsResponseDto> {
        const response = await this.getPartnerEnterpriseDocumentsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retornar informações legais de empresa parceira
     */
    async getPartnerEnterpriseLegalInformationRaw(requestParameters: GetPartnerEnterpriseLegalInformationRequest): Promise<runtime.ApiResponse<GetPartnerEnterpriseLegalInformationResponseDto>> {
        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling getPartnerEnterpriseLegalInformation.');
        }

        if (requestParameters.taxId === null || requestParameters.taxId === undefined) {
            throw new runtime.RequiredError('taxId','Required parameter requestParameters.taxId was null or undefined when calling getPartnerEnterpriseLegalInformation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/digital-account/apps/{clientId}/.well-known/customers/{taxId}/legal-representatives`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters.clientId))).replace(`{${"taxId"}}`, encodeURIComponent(String(requestParameters.taxId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPartnerEnterpriseLegalInformationResponseDtoFromJSON(jsonValue));
    }

    /**
     * Retornar informações legais de empresa parceira
     */
    async getPartnerEnterpriseLegalInformation(requestParameters: GetPartnerEnterpriseLegalInformationRequest): Promise<GetPartnerEnterpriseLegalInformationResponseDto> {
        const response = await this.getPartnerEnterpriseLegalInformationRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retorna a chave pública
     */
    async getPublicKeyRaw(): Promise<runtime.ApiResponse<GetDigitalAuthorizationDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/digital-account/.well-known/jwks`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetDigitalAuthorizationDtoFromJSON(jsonValue));
    }

    /**
     * Retorna a chave pública
     */
    async getPublicKey(): Promise<GetDigitalAuthorizationDto> {
        const response = await this.getPublicKeyRaw();
        return await response.value();
    }

    /**
     * abrir uma conta digital
     */
    async openDigitalAccountRaw(requestParameters: OpenDigitalAccountRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.openDigitalAccountDto === null || requestParameters.openDigitalAccountDto === undefined) {
            throw new runtime.RequiredError('openDigitalAccountDto','Required parameter requestParameters.openDigitalAccountDto was null or undefined when calling openDigitalAccount.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/digital-account/open`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OpenDigitalAccountDtoToJSON(requestParameters.openDigitalAccountDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * abrir uma conta digital
     */
    async openDigitalAccount(requestParameters: OpenDigitalAccountRequest): Promise<void> {
        await this.openDigitalAccountRaw(requestParameters);
    }

    /**
     * Retorna credenciais de acesso
     */
    async removeDigitalCredentialsRaw(requestParameters: RemoveDigitalCredentialsRequest): Promise<runtime.ApiResponse<GetDigitalCredentialsDto>> {
        if (requestParameters.document === null || requestParameters.document === undefined) {
            throw new runtime.RequiredError('document','Required parameter requestParameters.document was null or undefined when calling removeDigitalCredentials.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/digital-account/remove-digital-credentials/{document}`.replace(`{${"document"}}`, encodeURIComponent(String(requestParameters.document))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetDigitalCredentialsDtoFromJSON(jsonValue));
    }

    /**
     * Retorna credenciais de acesso
     */
    async removeDigitalCredentials(requestParameters: RemoveDigitalCredentialsRequest): Promise<GetDigitalCredentialsDto> {
        const response = await this.removeDigitalCredentialsRaw(requestParameters);
        return await response.value();
    }

    /**
     * realizar o SSO BTG
     */
    async ssoRaw(requestParameters: SsoRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling sso.');
        }

        if (requestParameters.digitalAccountUserDataDto === null || requestParameters.digitalAccountUserDataDto === undefined) {
            throw new runtime.RequiredError('digitalAccountUserDataDto','Required parameter requestParameters.digitalAccountUserDataDto was null or undefined when calling sso.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/digital-account/{sellerId}/sso`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DigitalAccountUserDataDtoToJSON(requestParameters.digitalAccountUserDataDto),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * realizar o SSO BTG
     */
    async sso(requestParameters: SsoRequest): Promise<string> {
        const response = await this.ssoRaw(requestParameters);
        return await response.value();
    }

}
