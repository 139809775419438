import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Flex,
	FlexProps,
	Icon,
	Stack,
	Text,
	useBreakpointValue,
	useTheme,
} from '@chakra-ui/react';
import * as React from 'react';
import { BiBuilding, BiNotification } from 'react-icons/bi';
import { BsBuilding } from 'react-icons/bs';
import { FaUser, FaUserTie, FaExclamationTriangle, FaUniversity } from 'react-icons/fa';
import { GiPayMoney, GiReceiveMoney, GiStairsGoal, GiSuitcase } from 'react-icons/gi';
import { GoLaw } from 'react-icons/go';
import { IoDocumentOutline, IoGrid } from 'react-icons/io5';
import { IconType } from 'react-icons/lib/cjs';
import { useHistory, useLocation } from 'react-router-dom';

import Title from 'components/Title';

import { PermissionActionsEnum } from '../../clients';
import Can from '../../contexts/Can';
import * as contextTheme from '../../contexts/ThemeProvider';

const MENU_HEIGHT = '30px';

export type MenuItemProps = {
	id: any;
	label: string;
	url?: string;
	submenus?: Array<MenuItemProps>;
	icon?: IconType;
	className?: string;
};

const menuItems: Array<MenuItemProps> = [
	{
		id: 'Dashboard',
		label: 'Resumo',
		url: '/admin/dashboard',
		icon: IoGrid,
		className: 'menu__dashboard',
	},
	{
		id: 'Sell',
		label: 'Gerar Venda',
		className: 'menu__sell',
		submenus: [
			{
				id: 'POS',
				label: 'Máquina',
				url: '/admin/venda/pos',
				className: 'menu__sub__pos',
			},
			{
				id: 'Billet',
				label: 'Boleto',
				url: '/admin/venda/boleto',
				className: 'menu__sub__billet',
			},
			{
				id: 'Pix',
				label: 'Pix QRCode',
				url: '/admin/venda/pix',
				className: 'menu__sub__pix',
			},
			{
				id: 'BillPayment',
				label: 'Pagamento de conta',
				url: '/admin/venda/pagamento_de_conta',
				className: 'menu__sub__bill-payment',
			},
			{
				id: 'Link',
				label: 'Link',
				url: '/admin/venda/link',
				className: 'menu__sub__link',
			},
			{
				id: 'ProtestLink',
				label: 'Link de Protesto',
				url: '/admin/venda/link-protesto',
				className: 'menu__sub__prolink',
			},
		],
		icon: IoDocumentOutline,
	},
	{
		id: 'Financial',
		label: 'Gestão Financeira',
		className: 'menu__financial',
		submenus: [
			{
				id: 'SalesHistory',
				label: 'Histórico de Vendas',
				url: '/admin/sales-history',
				className: 'menu__sub__sales-history',
			},
			{
				id: 'SalesHistoryPayment',
				label: 'Histórico de Pagamentos',
				url: '/admin/sales-history-payment',
			},
			{
				id: 'PaymentsHistory',
				label: 'Histórico de Liquidação',
				url: '/admin/payments-history',
				className: 'menu__sub__payments-history',
			},
			{
				id: 'BillPaymentHistory',
				label: 'Histórico de Pagamentos de Conta',
				url: '/admin/payment-count-history',
				className: 'menu__sub__bill-payment-history',
			},
			{
				id: 'Calendar',
				label: 'Calendário',
				url: '/admin/calendario',
				className: 'menu__sub__calendar',
			},
			{
				id: 'Reports',
				label: 'Relatório',
				url: '/admin/relatorios',
				className: 'menu__sub__reports',
			},
			{
				id: 'UploadRetFile',
				label: 'Arquivos Retorno',
				url: '/admin/shipping-return/upload',
				className: 'menu__sub__upload',
			},
		],
		icon: GoLaw,
	},
	{
		id: 'ProtocolsManagement',
		label: 'Gestão de Protocolos',
		submenus: [
			{
				id: 'Protocols',
				label: 'Protocolos',
				url: '/admin/protocolos',
			},
			{
				id: 'ProtocolsHistory',
				label: 'Histórico',
				url: '/admin/historico-de-protocolos',
			},
			{
				id: 'ProtocolsCustom',
				label: 'Personalização',
				url: '/admin/personalizacao-de-protocolos',
			},
		],
		icon: GiSuitcase,
	},
	{
		id: 'MyAccount',
		label: 'Minha Conta',
		className: 'menu__my-account',
		submenus: [
			{
				id: 'MyData',
				label: 'Meus Dados',
				url: '/admin/myData',
				className: 'menu__sub__my-data',
			},
			{
				id: 'MyPlan',
				label: 'Meu Plano',
				url: '/admin/plan',
				className: 'menu__sub__my-plan',
			},
			{
				id: 'Users',
				label: 'Usuários',
				url: '/admin/usuarios',
				className: 'menu__sub__users',
			},
			{
				id: 'BackofficeUsers',
				label: 'Usuários Backoffice',
				url: '/admin/backoffice/usuarios',
				className: 'menu__sub__backoffice-users',
			},
			{
				id: 'MerchantUsers',
				label: 'Usuários Merchant',
				url: '/admin/merchant/usuarios',
				className: 'menu__sub__merchant-users',
			},
			{
				id: 'BackofficePos',
				label: 'Pos Backoffice',
				url: '/admin/backoffice/pos',
				className: 'menu__sub__backoffice-pos',
			},
			{
				id: 'Roles',
				label: 'Permissões',
				url: '/admin/permissoes',
			},
			{
				id: 'Customization',
				label: 'Customização',
				url: '/admin/customizacao',
				className: 'menu__sub__customization',
			},
			{
				id: 'EmailsCustom',
				label: 'Personalização de Emails',
				url: '/admin/personalizacao-emails',
				className: 'menu__sub__customization',
			},
			{
				id: 'ReportCustomization',
				label: 'Personalização de Relatórios',
				url: '/admin/personalizacao-relatorios',
				className: 'menu__sub__customization',
			},
			{
				id: 'CheckoutsCustomization',
				label: 'Personalização de Checkouts',
				url: '/admin/personalizacao-checkout',
				className: 'menu__sub__customization',
			},
			{
				id: 'PaperRollOrder',
				label: 'Pedido de Bobinas',
				url: '/admin/pedido-bobinas',
				className: 'menu__sub__paper-roll-order',
			},
			{
				id: 'FindDda',
				label: 'Localizar DDA',
				url: '/admin/dda',
				className: 'menu__sub__dda',
			},
			{
				id: 'PosPassword',
				label: 'Senha Pos Android',
				url: '/admin/pos-password',
				className: 'menu__sub__pos-password',
			},
		],
		icon: FaUser,
	},
	{
		id: 'DigitalAccount',
		label: 'Conta Digital',
		url: '/admin/digital-account',
		icon: FaUniversity,
		className: 'menu__digital_account',
	},
	{
		id: 'Sellers',
		label: 'Estabelecimentos',
		url: '/admin/estabelecimentos',
		icon: BsBuilding,
		className: 'menu__sellers',
	},
	{
		id: 'Merchants',
		label: 'Merchants',
		url: '/admin/merchants',
		icon: BiBuilding,
		className: 'menu__merchants',
	},
	{
		id: 'Responsibles',
		label: 'Responsáveis',
		url: '/admin/responsaveis',
		icon: FaUserTie,
		className: 'menu__responsibles',
	},
	{
		id: 'CostPlans',
		label: 'Planos de Custo',
		url: '/admin/planos-de-custo',
		icon: GiPayMoney,
		className: 'menu__cost-plans',
	},
	{
		id: 'SalesPlan',
		label: 'Planos de Venda',
		url: '/admin/planos-de-venda',
		icon: GiReceiveMoney,
		className: 'menu__sales-plan',
	},
	{
		id: 'Alerts',
		label: 'Alertas',
		icon: FaExclamationTriangle,
		className: 'menu__alerts',
		submenus: [
			{
				id: 'MonthlyTransactionsAlerts',
				label: 'Alertas Mensais',
				url: '/admin/alertas-mensais',
				className: 'menu__sub__monthly-alerts',
			},
			{
				id: 'AssignmentTransactionAlerts',
				label: 'Alertas por Atribuição',
				url: '/admin/alertas-atribuicao',
				className: 'menu__sub__assignment-transaction-alert',
			},
		],
	},
	{
		id: 'Goals',
		label: 'Metas',
		url: '/admin/metas',
		icon: GiStairsGoal,
		className: 'menu__goals',
	},
	{
		id: 'Notifications',
		label: 'Notificações',
		icon: BiNotification,
		className: 'menu__notifications',
		submenus: [
			{
				id: 'CreateNotifications',
				label: 'Cadastrar Notificações',
				url: '/admin/cadastrar-notificacoes',
				className: 'menu__create-notifications',
			},
			{
				id: 'SearchNotifications',
				label: 'Visualizar Notificações',
				url: '/admin/notificacoes',
				className: 'menu__search-notifications',
			},
		],
	},
];

const MenuItem: React.FC<MenuItemProps & FlexProps> = (props: MenuItemProps & FlexProps) => {
	const history = useHistory();
	const location = useLocation();
	const { theme } = contextTheme.useTheme();

	const { icon, ...parsedProps } = props;

	return (
		<AccordionItem
			id={`${props.id}`}
			w='100%'
			border={0}
			{...(location.pathname !== props.url && { _hover: { bg: theme?.default_color || 'defaultGrey', cursor: 'pointer', rounded: 'md' } })}
		>
			<Flex
				flexDir='column'
				w='100%'
				justifyContent='center'
				_hover={{ bg: theme?.default_color || 'defaultGrey', cursor: 'pointer', rounded: 'md', opacity: 0.7 }}
				{...(location.pathname === props.url && { backgroundColor: theme?.default_color || 'defaultGrey', rounded: 'md' })}
				{...(props.url && props.id !== 'Fees'
					? { onClick: () => history.push(`${props.url}`) }
					: { onClick: () => window.open(props.url, '_blank') })}
				{...parsedProps}
			>
				<Stack isInline spacing={5} alignItems='center' w='100%'>
					{props.icon && <Icon as={props.icon} boxSize={5} color={theme?.menu_text_color || 'primary'} />}
					<Text id={`${props.id}-label-text`} as='span' fontSize={props.fontSize || 'md'} fontWeight='bold'>
						{props.label}
					</Text>
				</Stack>
			</Flex>
		</AccordionItem>
	);
};

export const MobileMenu: React.FC = () => {
	return (
		<Stack w='100%'>
			<Accordion allowToggle>
				<MenuItemsList />
			</Accordion>
		</Stack>
	);
};

const Menu: React.FC = () => {
	const { sizes } = useTheme();

	const isMobile = useBreakpointValue({ base: true, lg: false });

	return (
		<>
			{!isMobile && (
				<Flex
					minHeight={`calc(100vh - ${sizes.headerHeight})`}
					width={[null, null, null, '25%', '20%']}
					flexDirection='column'
					justifyContent='space-between'
					pt='12'
					px='4'
				>
					<Stack w='100%'>
						<Accordion allowToggle>
							<MenuItemsList />
						</Accordion>
					</Stack>

					<Flex mb='8' mt='12'>
						<Title id='parcela-services-title' fontSize='sm' textAlign='center'>
							Desenvolvido por Parcela Express Serviços Digitais SA.
						</Title>
					</Flex>
				</Flex>
			)}
		</>
	);
};

const MenuItemsList: React.FC = () => {
	const { theme } = contextTheme.useTheme();
	return (
		<>
			{menuItems.map((menuItem: MenuItemProps, index: number) => (
				<Can do={PermissionActionsEnum.Read} on={menuItem.id} key={index}>
					<>
						{!menuItem.submenus && (
							<MenuItem
								{...menuItem}
								fontSize='md'
								h={MENU_HEIGHT}
								px={3}
								color={theme?.menu_text_color ? theme?.menu_text_color : 'primary'}
								className={menuItem?.className}
							>
								{menuItem.label}
							</MenuItem>
						)}
						{menuItem.submenus && (
							<AccordionItem id={`${menuItem.id}-acordion-item`} border={0}>
								<AccordionButton
									id={`${menuItem.id}-acordion-button`}
									py={0}
									px={3}
									h={MENU_HEIGHT}
									_hover={{ bg: theme?.default_color || 'defaultGrey', cursor: 'pointer', rounded: 'md', opacity: 0.7 }}
									_focus={{ outline: 'none' }}
									_active={{ outline: 'none' }}
								>
									{menuItem.icon && <Icon id={`${menuItem.id}-icon`} as={menuItem.icon} boxSize={5} color={theme?.menu_text_color || 'primary'} />}

									<Box flex='1' textAlign='left' ml='5'>
										<Text
											id={`${menuItem.id}-label-text`}
											fontSize='md'
											fontWeight='bold'
											color={theme?.menu_text_color || 'primary'}
										>
											{menuItem.label}
										</Text>
									</Box>

									<AccordionIcon id={`${menuItem.id}-acordion-icon`} color={theme?.menu_text_color || 'primary'} />
								</AccordionButton>
								<AccordionPanel id={`${menuItem.id}-acordion-panel`} mb={0} px={0} width='100%' py={0}>
									{menuItem.submenus.map((subMenuItem: MenuItemProps, subMenuItemIndex: number) => (
										<Can do={PermissionActionsEnum.Read} on={subMenuItem.id} key={subMenuItemIndex}>
											<MenuItem
												key={subMenuItemIndex}
												{...subMenuItem}
												h='30px'
												my='2'
												pl='14'
												fontSize='md'
												fontWeight='bold'
												color={theme?.menu_text_color || 'primary'}
											>
												{subMenuItem.label}
											</MenuItem>
										</Can>
									))}
								</AccordionPanel>
							</AccordionItem>
						)}
					</>
				</Can>
			))}
		</>
	);
};

export default Menu;
