import React from 'react';
import { ImFileText2 } from 'react-icons/im';
import { Flex, Text, Icon, Button } from '@chakra-ui/react';
type props = {
  name: string;
  remove?: () => void;
  type?: string;
};
const Attachment: React.FC<props> = ({ name, remove, type }) => {
  return (
    <Flex
      direction='column'
      maxW={{ base: '25vw', md: '10vw', xl: '6vw' }}
      minWidth={{ base: '25vw', md: '10vw', xl: '6vw' }}
      mx={5}
      mb={5}
    >
      { type && (
        <Text id='open-account-attachment-type' alignSelf='center'>{type}</Text>
      ) }
      <Icon
        as={ImFileText2}
        w={'55%'}
        h={'80%'}
        alignSelf={'center'}
      />
      <Text id='name-length-text' fontSize='md' alignSelf={'center'}>
        {
          name.length > 17
            ? `${name.substring(0, 5)}...${name.substring(name.length - 7)}`
            : name
        }
      </Text>
      {remove && <Button backgroundColor='white' alignSelf='end'>
        <Text id='remove-text' fontSize='sm' color='blue.800' as='u' onClick={() => remove()}>
          Excluir
        </Text>
      </Button>}
    </Flex>
  );
};
export default Attachment;
