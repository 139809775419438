/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateExportSettlementBatchSalesListDto
 */
export interface CreateExportSettlementBatchSalesListDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateExportSettlementBatchSalesListDto
     */
    status?: Array<CreateExportSettlementBatchSalesListDtoStatusEnum>;
    /**
     * 
     * @type {string}
     * @memberof CreateExportSettlementBatchSalesListDto
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateExportSettlementBatchSalesListDto
     */
    amount?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateExportSettlementBatchSalesListDto
     */
    original_amount?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateExportSettlementBatchSalesListDto
     */
    type?: Array<CreateExportSettlementBatchSalesListDtoTypeEnum>;
    /**
     * 
     * @type {string}
     * @memberof CreateExportSettlementBatchSalesListDto
     */
    name?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateExportSettlementBatchSalesListDto
     */
    form_payment?: Array<CreateExportSettlementBatchSalesListDtoFormPaymentEnum>;
    /**
     * 
     * @type {string}
     * @memberof CreateExportSettlementBatchSalesListDto
     */
    extension?: CreateExportSettlementBatchSalesListDtoExtensionEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateExportSettlementBatchSalesListDto
     */
    seller_id?: string;
}

/**
* @export
* @enum {string}
*/
export enum CreateExportSettlementBatchSalesListDtoStatusEnum {
    Pending = 'pending',
    PendingCapture = 'pending_capture',
    InProgressCapture = 'in_progress_capture',
    Succeeded = 'succeeded',
    Canceled = 'canceled',
    PendingCancel = 'pending_cancel',
    Abort = 'abort',
    Failed = 'failed',
    WaitingConfirmation = 'waiting_confirmation',
    Expired = 'expired',
    WaitingCashIn = 'waiting_cashIn',
    Refused = 'refused'
}/**
* @export
* @enum {string}
*/
export enum CreateExportSettlementBatchSalesListDtoTypeEnum {
    Boleto = 'boleto',
    Pos = 'pos',
    PaymentLink = 'payment_link',
    ProtestLink = 'protest_link',
    Online = 'online',
    AutoGeneratePaymentLink = 'auto_generate_payment_link',
    AutoGenerateProtestLink = 'auto_generate_protest_link',
    BilletCode = 'billet_code',
    BillPayment = 'bill_payment',
    Digital = 'digital'
}/**
* @export
* @enum {string}
*/
export enum CreateExportSettlementBatchSalesListDtoFormPaymentEnum {
    Credit = 'credit',
    Debit = 'debit',
    Pix = 'pix',
    Billet = 'billet',
    BilletCode = 'billet_code',
    PixPos = 'pix_pos'
}/**
* @export
* @enum {string}
*/
export enum CreateExportSettlementBatchSalesListDtoExtensionEnum {
    Xls = 'xls',
    Csv = 'csv',
    Json = 'json',
    Pdf = 'pdf'
}

export function CreateExportSettlementBatchSalesListDtoFromJSON(json: any): CreateExportSettlementBatchSalesListDto {
    return CreateExportSettlementBatchSalesListDtoFromJSONTyped(json, false);
}

export function CreateExportSettlementBatchSalesListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateExportSettlementBatchSalesListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': !exists(json, 'status') ? undefined : json['status'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'original_amount': !exists(json, 'original_amount') ? undefined : json['original_amount'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'form_payment': !exists(json, 'form_payment') ? undefined : json['form_payment'],
        'extension': !exists(json, 'extension') ? undefined : json['extension'],
        'seller_id': !exists(json, 'seller_id') ? undefined : json['seller_id'],
    };
}

export function CreateExportSettlementBatchSalesListDtoToJSON(value?: CreateExportSettlementBatchSalesListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': value.status,
        'description': value.description,
        'amount': value.amount,
        'original_amount': value.original_amount,
        'type': value.type,
        'name': value.name,
        'form_payment': value.form_payment,
        'extension': value.extension,
        'seller_id': value.seller_id,
    };
}


