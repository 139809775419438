import React from 'react';

import { Flex, Box, Image, Spinner } from '@chakra-ui/react';

import QRCode from 'react-qr-code';

import Button from '../../../components/Button';
import NewModal from 'containers/NewModal';
import { useReactToPrint } from 'react-to-print';
import { useTheme } from 'contexts/ThemeProvider';
import Text from '../../../components/Text';
import { logoUrl } from '../../../config/variables';

type PixModalProps = {
	isOpen: boolean;
	setIsModalOpen(open: boolean): void;
	isLoading: boolean;
	setIsLoading(value): void;
	seller: any;
	qrcode: string;
	resetForm(): void;
};

const PixModal: React.FC<PixModalProps> = ({ isOpen, setIsModalOpen, isLoading, setIsLoading, seller, qrcode, resetForm }) => {
	const componentRef = React.useRef(null);
	const onBeforeGetContentResolve = React.useRef<(() => void) | null>(null);
	const [hasCopied, setHasCopied] = React.useState<Boolean>(false);

	const { theme } = useTheme();

	const reactToPrintContent = React.useCallback(() => {
		return componentRef.current;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [componentRef.current]);

	const handleOnBeforeGetContent = React.useCallback(() => {
		console.log('`onBeforeGetContent` called'); // tslint:disable-line no-console

		return new Promise<void>((resolve) => {
			onBeforeGetContentResolve.current = resolve;

			setTimeout(() => {
				resolve();
			}, 2000);
		});
	}, []);

	const handlePrint = useReactToPrint({
		content: reactToPrintContent,
		documentTitle: 'QRCode Pix',
		onBeforeGetContent: handleOnBeforeGetContent,
		removeAfterPrint: true,
	});

	const handleCopy = () => {
		navigator.clipboard.writeText(qrcode);
		setHasCopied(true);
	};

	return (
		<NewModal
			isOpen={isOpen}
			onClose={() => {
				setIsModalOpen(!isOpen);
				setHasCopied(false);
				setIsLoading(false);
				resetForm();
			}}
			isCentered
			showCloseButton
		>
			<Box borderRadius='md' className='modal__pix-infos-modal' mt='2'>
				<Flex py={4} px={12} flexDirection='column'>
					<Text id='qr-code-text' align='center' color='darkGrey' fontSize='md' fontWeight='bold'>
						QRCode Pix
					</Text>

					<Flex justifyContent='center' flexDirection='column' align='center' p={5} className='qrcode__pix-qrcode' ref={componentRef}>
						{!qrcode ? (
							<Spinner thickness='4px' speed='0.3s' emptyColor='gray.200' color={theme?.text_color || 'blue.500'} size='xl' mt='2' mb='2' />
						) : (
							<>
								<Flex justifyContent='center' flexDirection='column' align='center' p={1} mb='2'>
									<Image id='seller-logo-image' src={seller?.logo?.url || logoUrl()} w={50} />
									<Text id='seller-name-text' color='darkGrey' fontSize='md'>
										{seller.name}
									</Text>
								</Flex>
								<QRCode size={200} value={qrcode} />
							</>
						)}
					</Flex>

					<Button id='copy-paste-button' bgColor='primary' onClick={handleCopy} isLoading={isLoading} mt='2'>
						{hasCopied ? 'Copiado!' : 'Copia e Cola'}
					</Button>

					<Button id='print-button' bgColor='primary' onClick={handlePrint} isLoading={isLoading} mt='2'>
						Imprimir
					</Button>
				</Flex>
			</Box>
		</NewModal>
	);
};

export default PixModal;
