import React from 'react';

import { Modal, ModalOverlay, ModalContent, ModalBody, ModalCloseButton, ModalProps } from '@chakra-ui/react';

type NewModalProps = ModalProps & {
	isOpen: boolean;
	onClose: () => void;
	showCloseButton?: boolean;
	children: React.ReactElement | React.ReactElement[];
	p?: number;
	maxWidth?: { base: string; md: string; lg: string; xl: string; '2xl': string };
	closeOnOverlayClick?: boolean;
};

const NewModal: React.FC<NewModalProps> = ({
	children,
	isOpen,
	showCloseButton,
	onClose,
	p = 2,
	maxWidth,
	closeOnOverlayClick = true,
	...rest
}) => {
	return (
		<Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={closeOnOverlayClick} closeOnEsc={false} {...rest}>
			<ModalOverlay />
			<ModalContent {...(maxWidth ? { maxWidth } : {})}>
				{showCloseButton && <ModalCloseButton onClick={() => onClose()} _focus={{ outline: 'none' }} />}
				<ModalBody p={p}>{children}</ModalBody>
			</ModalContent>
		</Modal>
	);
};

export default NewModal;
