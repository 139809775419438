/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetMerchantPermissionDto
 */
export interface GetMerchantPermissionDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof GetMerchantPermissionDto
     */
    allowed_fields?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetMerchantPermissionDto
     */
    denied_fields: Array<string>;
}

export function GetMerchantPermissionDtoFromJSON(json: any): GetMerchantPermissionDto {
    return GetMerchantPermissionDtoFromJSONTyped(json, false);
}

export function GetMerchantPermissionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetMerchantPermissionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allowed_fields': !exists(json, 'allowed_fields') ? undefined : json['allowed_fields'],
        'denied_fields': json['denied_fields'],
    };
}

export function GetMerchantPermissionDtoToJSON(value?: GetMerchantPermissionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allowed_fields': value.allowed_fields,
        'denied_fields': value.denied_fields,
    };
}


