/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateDigitalAuthenticationDto
 */
export interface CreateDigitalAuthenticationDto {
    /**
     * 
     * @type {string}
     * @memberof CreateDigitalAuthenticationDto
     */
    authorization_code: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDigitalAuthenticationDto
     */
    uri_path: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDigitalAuthenticationDto
     */
    document: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateDigitalAuthenticationDto
     */
    sellersIds: Array<string>;
}

export function CreateDigitalAuthenticationDtoFromJSON(json: any): CreateDigitalAuthenticationDto {
    return CreateDigitalAuthenticationDtoFromJSONTyped(json, false);
}

export function CreateDigitalAuthenticationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateDigitalAuthenticationDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'authorization_code': json['authorization_code'],
        'uri_path': json['uri_path'],
        'document': json['document'],
        'sellersIds': json['sellersIds'],
    };
}

export function CreateDigitalAuthenticationDtoToJSON(value?: CreateDigitalAuthenticationDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'authorization_code': value.authorization_code,
        'uri_path': value.uri_path,
        'document': value.document,
        'sellersIds': value.sellersIds,
    };
}


