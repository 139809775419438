import { Flex } from '@chakra-ui/react';
import React from 'react';
import { useToasts } from 'react-toast-notifications';
import FileUploadInput from 'containers/FileUploadInput';
import Button from '../../../components/Button';
import FormSelect from '../../../components/Form/FormSelect';
import Text from '../../../components/Text';
import { open_digital_account_file_type } from '../../../config/constants';
import NewModal from '../../../containers/NewModal';
import Attachment from './OpenAccountAttachment';
type props = {
	attachments: any[];
	changeAttachments: (file: any[]) => void;
};
const OpenAccountAttachments: React.FC<props> = ({ attachments, changeAttachments }) => {
	const [attachment, setAttachment] = React.useState({
		name: '',
		data: '',
		extension: '',
		size: '',
	});
	const [type, setType] = React.useState('');
	const [isOpen, setIsOpen] = React.useState<boolean>(false);
	const { addToast } = useToasts();
	const maxSize = 10000000;
	const allowedExtensions = ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'csv', 'png', 'jpg', 'jpeg', 'zip', 'rar'];
	const uploadFile = async (file) => {
		if (type === '') {
			return;
		}
		const processedFiles: any[] = [];
		const { name, extension, size, data } = file;
		processedFiles.push({
			name,
			extension,
			size,
			data,
			type,
		});
		changeAttachments([...attachments, ...processedFiles]);
		setIsOpen(false);
		setAttachment({
			name: '',
			data: '',
			extension: '',
			size: '',
		});
		setType('');
	};
	const addAttachment = async (file) => {
		const totalSize = attachments.reduce((total, { size }) => {
			return total + (size || 0);
		}, 0);
		const { name, size } = file;
		const processedFile = await getBase64(file);
		const extension = getExtension(name);
		const isValidFile = verifyFile(extension, size, totalSize);
		if (isValidFile) {
			setAttachment({
				name,
				extension,
				size,
				data: processedFile as string,
			});
			setIsOpen(true);
		}
	};
	const getExtension = (filename: string): string => {
		const re = /(?:\.([^.]+))?$/;
		const ext = re.exec(filename)?.[1];
		return ext || '';
	};
	const getBase64 = async (file) => {
		return new Promise((resolve) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => {
				if (reader.result !== null) {
					resolve(reader.result);
				} else {
					resolve(null);
				}
			};
		});
	};
	const verifyFile = (extension: string, size: number, totalSize: number): boolean => {
		if (size + totalSize > maxSize) {
			addToast('Anexo excede limite máximo de 10MB', {
				appearance: 'error',
				autoDismiss: true,
			});
			return false;
		}
		if (!allowedExtensions.includes(extension)) {
			addToast('Extensão não aceita', {
				appearance: 'error',
				autoDismiss: true,
			});
			return false;
		}
		return true;
	};
	const removeFile = (index: number) => {
		const updatedAttachments = [...attachments];
		updatedAttachments.splice(index, 1);
		changeAttachments(updatedAttachments);
	};
	return (
		<>
			<Flex direction='row' borderWidth={0.2} borderColor='darkGrey' rounded='md' minHeight={100} mb={5} paddingY={5} overflow='auto'>
				{attachments.length ? (
					attachments.map((attachment, index) => {
						return <Attachment name={attachment.name} remove={() => removeFile(index)} type={attachment.type} />;
					})
				) : (
					<Text id='file-include-text' fontSize='md' minW='100%' textAlign='center' paddingTop={5}>
						Nenhum anexo incluído
					</Text>
				)}
			</Flex>
			<Flex direction='column'>
				<Flex alignSelf='center'>
					<FileUploadInput
						id='file-upload-input'
						label='Importar anexos'
						inputProps={{
							multiple: false,
							onChange: (e) => {
								if (e.target.files?.length) {
									addAttachment(e.target.files[0]);
								}
							},
						}}
					/>
				</Flex>
				<Text id='max-size-text' fontSize='sm' textAlign='center' maxWidth='30%' alignSelf='center'>
					Tamanho máximo: 10MB
				</Text>
				<Text id='paterns-text' fontSize='sm' textAlign='center' maxWidth='30%' alignSelf='center'>
					Formatos aceitos:
				</Text>
				<Text id='extensions-text' fontSize='sm' textAlign='center' maxWidth='30%' wordBreak='break-word' alignSelf='center'>
					{allowedExtensions.map((extension) => `.${extension}, `)}
				</Text>
			</Flex>
			<NewModal isOpen={isOpen} onClose={() => setIsOpen(false)}>
				<Flex w='100&' alignItems='center' direction='column' p={3}>
					<FormSelect id='open-account-attachments-modal-select' m={3} onChange={(e) => setType(e.target.value)} name='type'>
						<option value='' key=''>
							Tipo
						</option>
						{open_digital_account_file_type.map((type) => (
							<option value={type.value} key={type.key}>
								{type.text}
							</option>
						))}
					</FormSelect>
					<Attachment name={attachment.name} />
					<Flex justifyContent='end'>
						<Button id='open-account-attachments-modal-cancel' mx={3} bgColor='red.400'>
							Cancelar
						</Button>
						<Button id='open-account-attachments-modal-confirm' bgColor='primary' onClick={() => uploadFile(attachment)}>
							Confirmar
						</Button>
					</Flex>
				</Flex>
			</NewModal>
		</>
	);
};
export default OpenAccountAttachments;
