export const isProduction: boolean = process.env.REACT_APP_STAGE === 'production';
export const logoUrl = () => {
	switch (window.location.hostname.split('.')[0]) {
		case 'cartorios':
			return process.env.REACT_APP_LOGO!;
		case 'sipeonr':
			return process.env.REACT_APP_LOGO_ONR!;
		default:
			return process.env.REACT_APP_LOGO!;
	}
}