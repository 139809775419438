import { Flex, GridItem, SimpleGrid } from '@chakra-ui/react';
import React from 'react';

import FormControl from '../../../components/Form/FormControl';
import FormErrorMessage from '../../../components/Form/FormErrorMessage';
import FormInput from '../../../components/Form/FormInput';
import FormLabel from '../../../components/Form/FormLabel';
import FormPhoneInput from '../../../components/Form/FormPhoneInput';
import FormSelect from '../../../components/Form/FormSelect';
import FormZipcodeInput from '../../../components/Form/FormZipcodeInput';
import Title from '../../../components/Title';
import { seller_assignment, states } from '../../../config/constants';
import getzipcodeService from '../../../services/getzipcode.service';
import FormCpfCnpjInput from '../../../components/Form/FormCpfCnpjInput';
import FormCnsInput from '../../../components/Form/FormCnsInput';

type SellerDataFormTypes = {
	setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
};

const SellerDataForm: React.FC<SellerDataFormTypes> = ({ setFieldValue }) => {
	const onSearchResults = (data) => {
		setFieldValue('address.street', data.logradouro);
		setFieldValue('address.district', data.bairro);
		setFieldValue('address.city', data.localidade);
		setFieldValue('address.state', data.uf);
	};

	return (
		<Flex direction='column' my='20px'>
			<Title id='pre-register-serventia-title' textAlign='center' textDecoration='underline' mb='30px'>
				Dados da Serventia
			</Title>
			<SimpleGrid columns={12} color='gray.700' gap={2}>
				<GridItem colSpan={{ base: 12, xl: 12 }}>
					<FormControl id='pre-register-form-control-name' isRequired width='100%'>
						<FormLabel id='pre-register-seller-name-label' fontSize='md' fontWeight='bold'>
							Razão Social
						</FormLabel>
						<FormInput id='pre-register-seller-name-form-input' name='name' />
						<FormErrorMessage id='pre-register-seller-name-form-error-text' name='name' />
					</FormControl>
				</GridItem>
				<GridItem colSpan={{ base: 12, xl: 6 }}>
					<FormControl id='pre-register-form-control-cns' isRequired>
						<FormLabel id='pre-register-seller-cns-label' fontSize='md' fontWeight='bold'>
							CNS
						</FormLabel>
						<FormCnsInput id='pre-register-seller-cns-form-input' name='cns' />
						<FormErrorMessage id='pre-register-seller-cns-form-error-text' name='cns' />
					</FormControl>
				</GridItem>
				<GridItem colSpan={{ base: 12, xl: 6 }}>
					<FormControl id='pre-register-form-control-document' isRequired>
						<FormLabel id='pre-register-seller-document-label' fontSize='md' fontWeight='bold'>
							CNPJ/CPF
						</FormLabel>
						<FormCpfCnpjInput id='pre-register-seller-document-form-input' name='document' isSearchPayer={false} />
						<FormErrorMessage id='pre-register-seller-document-form-error-text' name='document' />
					</FormControl>
				</GridItem>
				<GridItem colSpan={{ base: 12 }}>
					<FormControl id='pre-register-form-control-description' isRequired width='100%'>
						<FormLabel id='pre-register-seller-description-label' fontSize='md' fontWeight='bold'>
							Nome Fantasia
						</FormLabel>
						<FormInput id='pre-register-seller-description-form-input' name='description' />
						<FormErrorMessage id='pre-register-seller-description-form-error-text' name='description' />
					</FormControl>
				</GridItem>
				<GridItem colSpan={{ base: 12, xl: 4 }}>
					<FormControl id='pre-register-form-control-zipcode' isRequired width='100%'>
						<FormLabel id='pre-register-address-zipcode-label' fontSize='md' fontWeight='bold'>
							CEP
						</FormLabel>
						<FormZipcodeInput
							id='pre-register-address-zipcode-form-zipcode-input'
							name='address.zipcode'
							performSearch={getzipcodeService}
							onSearchResults={(data) => onSearchResults(data)}
						/>
						<FormErrorMessage id='pre-register-address-zipcode-form-error-text' name='address.zipcode' />
					</FormControl>
				</GridItem>
				<GridItem colSpan={{ base: 12, xl: 6 }}>
					<FormControl id='pre-register-form-control-city' isRequired width='100%'>
						<FormLabel id='pre-register-seller-city-label' fontSize='md' fontWeight='bold'>
							Cidade
						</FormLabel>
						<FormInput id='pre-register-seller-city-form-input' name='address.city' />
						<FormErrorMessage id='pre-register-seller-city-form-error-text' name='address.city' />
					</FormControl>
				</GridItem>
				<GridItem colSpan={{ base: 12, xl: 2 }}>
					<FormControl id='pre-register-form-control-uf' isRequired width='100%'>
						<FormLabel id='pre-register-address-state-form-select' fontSize='md' fontWeight='bold'>
							UF
						</FormLabel>
						<FormSelect id='pre-register-address-state-form-select' fontSize='md' name='address.state'>
							<option id='pre-register-state-form-select-empty' value='' />
							{states.map((state) => (
								<option id={`pre-register-state-form-select-${state.value}`} value={state.value} key={state.value}>
									{state.text}
								</option>
							))}
						</FormSelect>
						<FormErrorMessage id='pre-register-address-state-form-error-text' name='payer.address.state' />
					</FormControl>
				</GridItem>
				<GridItem colSpan={{ base: 12, xl: 10 }}>
					<FormControl id='pre-register-form-control-street' isRequired>
						<FormLabel id='pre-register-seller-street-label' fontSize='md' fontWeight='bold'>
							Rua
						</FormLabel>
						<FormInput id='pre-register-seller-street-form-input' name='address.street' />
						<FormErrorMessage id='pre-register-seller-street-form-error-text' name='address.street' />
					</FormControl>
				</GridItem>
				<GridItem colSpan={{ base: 12, xl: 2 }}>
					<FormControl id='pre-register-form-control-number' isRequired>
						<FormLabel id='pre-register-seller-number-label' fontSize='md' fontWeight='bold'>
							Número
						</FormLabel>
						<FormInput id='pre-register-seller-number-form-input' name='address.number' />
						<FormErrorMessage id='pre-register-seller-number-form-error-text' name='address.number' />
					</FormControl>
				</GridItem>
				<GridItem colSpan={{ base: 12, xl: 6 }}>
					<FormControl id='pre-register-form-control-district' isRequired>
						<FormLabel id='pre-register-seller-district-label' fontSize='md' fontWeight='bold'>
							Bairro
						</FormLabel>
						<FormInput id='pre-register-seller-district-form-input' name='address.district' />
						<FormErrorMessage id='pre-register-seller-district-form-error-text' name='address.district' />
					</FormControl>
				</GridItem>
				<GridItem colSpan={{ base: 12, xl: 6 }}>
					<FormControl id='pre-register-form-control-complement'>
						<FormLabel id='pre-register-seller-complement-label' fontSize='md' fontWeight='bold'>
							Complemento
						</FormLabel>
						<FormInput id='pre-register-seller-complement-form-input' name='address.complement' />
						<FormErrorMessage id='pre-register-seller-complement-form-error-text' name='address.complement' />
					</FormControl>
				</GridItem>
				<GridItem colSpan={{ base: 12, xl: 6 }}>
					<FormControl id='pre-register-secondary-phone'>
						<FormLabel id='settings-secondary-phone-label' fontSize='md' fontWeight='bold'>
							Telefone
						</FormLabel>
						<FormPhoneInput id='secondary-phone-form-input' name='secondary_phone' />
						<FormErrorMessage id='seller-secondary-phone-form-error-text' name='secondary_phone' />
					</FormControl>
				</GridItem>
				<GridItem colSpan={{ base: 12, xl: 6 }}>
					<FormControl id='pre-register-tertiary-phone'>
						<FormLabel id='settings-tertiary-phone-label' fontSize='md' fontWeight='bold'>
							Telefone Secundário
						</FormLabel>
						<FormPhoneInput id='tertiary-phone-form-input' name='tertiary_phone' />
						<FormErrorMessage id='seller-tertiary-phone-form-error-text' name='tertiary_phone' />
					</FormControl>
				</GridItem>
				<GridItem colSpan={{ base: 12 }}>
					<FormControl id='pre-register-secondary-email'>
						<FormLabel id='pre-register-seller-secondary-email-label' fontSize='md' fontWeight='bold'>
							E-mail
						</FormLabel>
						<FormInput id='pre-register-seller-form-secondary-email-form-input' name='email' />
						<FormErrorMessage id='pre-register-seller-secondary-email-form-error-text' name='email' />
					</FormControl>
				</GridItem>
				<GridItem colSpan={{ base: 12 }}>
					<FormControl id='pre-register-tertiary-email'>
						<FormLabel id='pre-register-seller-tertiary-email-label' fontSize='md' fontWeight='bold'>
							E-mail Secundário
						</FormLabel>
						<FormInput id='pre-register-seller-form-tertiary-email-form-input' name='email' />
						<FormErrorMessage id='pre-register-seller-tertiary-email-form-error-text' name='email' />
					</FormControl>
				</GridItem>
				<GridItem colSpan={{ base: 12, xl: 6 }}>
					<FormControl id='pre-register-erp' isRequired>
						<FormLabel id='pre-registerseller-erp-label' fontSize='md' fontWeight='bold'>
							Sistema de gestão (ERP)
						</FormLabel>
						<FormInput id='pre-register-erp' name='erp' />
						<FormErrorMessage id='erp-form-error-text' name='erp' />
					</FormControl>
				</GridItem>
				<GridItem colSpan={{ base: 12, xl: 6 }}>
					<FormControl id='pre-register-seller_assignment'>
						<FormLabel id='pre-register-seller-seller_assignment-label' fontSize='md' fontWeight='bold'>
							Atribuiçao/convênio
						</FormLabel>
						<FormSelect id='pre-register-seller_assignment' name='seller_assignment'>
							<option value='' />
							{seller_assignment.map((assignment) => (
								<option value={assignment.key} key={assignment.key}>
									{assignment.text}
								</option>
							))}
						</FormSelect>
						<FormErrorMessage id='pre-register-seller-assignment-form-error-text' name='seller_assignment' />
					</FormControl>
				</GridItem>
			</SimpleGrid>
		</Flex>
	);
};

export default SellerDataForm;
