/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Billet,
    BilletFromJSON,
    BilletFromJSONTyped,
    BilletToJSON,
    Celcoin,
    CelcoinFromJSON,
    CelcoinFromJSONTyped,
    CelcoinToJSON,
    CostOption,
    CostOptionFromJSON,
    CostOptionFromJSONTyped,
    CostOptionToJSON,
    CostPlan,
    CostPlanFromJSON,
    CostPlanFromJSONTyped,
    CostPlanToJSON,
    GetSalePayerAddressDto,
    GetSalePayerAddressDtoFromJSON,
    GetSalePayerAddressDtoFromJSONTyped,
    GetSalePayerAddressDtoToJSON,
    Merchant,
    MerchantFromJSON,
    MerchantFromJSONTyped,
    MerchantToJSON,
    Payer,
    PayerFromJSON,
    PayerFromJSONTyped,
    PayerToJSON,
    PaymentType,
    PaymentTypeFromJSON,
    PaymentTypeFromJSONTyped,
    PaymentTypeToJSON,
    SalePlan,
    SalePlanFromJSON,
    SalePlanFromJSONTyped,
    SalePlanToJSON,
    Seller,
    SellerFromJSON,
    SellerFromJSONTyped,
    SellerToJSON,
    SettlementBatch,
    SettlementBatchFromJSON,
    SettlementBatchFromJSONTyped,
    SettlementBatchToJSON,
    SplitRules,
    SplitRulesFromJSON,
    SplitRulesFromJSONTyped,
    SplitRulesToJSON,
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './';

/**
 * 
 * @export
 * @interface Sale
 */
export interface Sale {
    /**
     * 
     * @type {string}
     * @memberof Sale
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Sale
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof Sale
     */
    protocol?: string;
    /**
     * 
     * @type {string}
     * @memberof Sale
     */
    acquirer_reference?: string;
    /**
     * 
     * @type {string}
     * @memberof Sale
     */
    acquirer_code?: SaleAcquirerCodeEnum;
    /**
     * 
     * @type {string}
     * @memberof Sale
     */
    acquirer_document?: string;
    /**
     * 
     * @type {string}
     * @memberof Sale
     */
    created_at?: string;
    /**
     * 
     * @type {string}
     * @memberof Sale
     */
    updated_at?: string;
    /**
     * 
     * @type {number}
     * @memberof Sale
     */
    original_amount: number;
    /**
     * 
     * @type {number}
     * @memberof Sale
     */
    amount?: number;
    /**
     * 
     * @type {number}
     * @memberof Sale
     */
    fee?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof Sale
     */
    absorb_costs?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Sale
     */
    status: SaleStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Sale
     */
    settlement_status?: SaleSettlementStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Sale
     */
    form_payment?: SaleFormPaymentEnum;
    /**
     * 
     * @type {string}
     * @memberof Sale
     */
    type: SaleTypeEnum;
    /**
     * 
     * @type {Seller}
     * @memberof Sale
     */
    seller: Seller;
    /**
     * 
     * @type {Merchant}
     * @memberof Sale
     */
    merchant: Merchant;
    /**
     * 
     * @type {SalePlan}
     * @memberof Sale
     */
    sale_plan: SalePlan;
    /**
     * 
     * @type {CostPlan}
     * @memberof Sale
     */
    cost_plan: CostPlan;
    /**
     * 
     * @type {string}
     * @memberof Sale
     */
    settlement_day?: string;
    /**
     * 
     * @type {User}
     * @memberof Sale
     */
    user?: User;
    /**
     * 
     * @type {PaymentType}
     * @memberof Sale
     */
    payment_type?: PaymentType;
    /**
     * 
     * @type {string}
     * @memberof Sale
     */
    payment_type_id?: string;
    /**
     * 
     * @type {Payer}
     * @memberof Sale
     */
    payer?: Payer;
    /**
     * 
     * @type {string}
     * @memberof Sale
     */
    payer_id?: string;
    /**
     * 
     * @type {SettlementBatch}
     * @memberof Sale
     */
    settlement_batch?: SettlementBatch;
    /**
     * 
     * @type {string}
     * @memberof Sale
     */
    settlement_batch_id?: string;
    /**
     * 
     * @type {Billet}
     * @memberof Sale
     */
    billet?: Billet;
    /**
     * 
     * @type {string}
     * @memberof Sale
     */
    billet_id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Sale
     */
    is_summed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Sale
     */
    is_visible?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Sale
     */
    installments?: number;
    /**
     * 
     * @type {string}
     * @memberof Sale
     */
    seller_id?: string;
    /**
     * 
     * @type {string}
     * @memberof Sale
     */
    seller_name?: string;
    /**
     * 
     * @type {string}
     * @memberof Sale
     */
    seller_document?: string;
    /**
     * 
     * @type {string}
     * @memberof Sale
     */
    seller_description: string;
    /**
     * 
     * @type {string}
     * @memberof Sale
     */
    merchant_id?: string;
    /**
     * 
     * @type {string}
     * @memberof Sale
     */
    merchant_name?: string;
    /**
     * 
     * @type {string}
     * @memberof Sale
     */
    merchant_document?: string;
    /**
     * 
     * @type {string}
     * @memberof Sale
     */
    payment_type_code?: string;
    /**
     * 
     * @type {string}
     * @memberof Sale
     */
    payment_type_description?: string;
    /**
     * 
     * @type {number}
     * @memberof Sale
     */
    payment_type_installments?: number;
    /**
     * 
     * @type {string}
     * @memberof Sale
     */
    payer_name?: string;
    /**
     * 
     * @type {string}
     * @memberof Sale
     */
    payer_email?: string;
    /**
     * 
     * @type {string}
     * @memberof Sale
     */
    payer_document?: string;
    /**
     * 
     * @type {string}
     * @memberof Sale
     */
    payer_phone?: string;
    /**
     * 
     * @type {GetSalePayerAddressDto}
     * @memberof Sale
     */
    payer_address?: GetSalePayerAddressDto;
    /**
     * 
     * @type {string}
     * @memberof Sale
     */
    sale_plan_id?: string;
    /**
     * 
     * @type {string}
     * @memberof Sale
     */
    sale_plan_name?: string;
    /**
     * 
     * @type {string}
     * @memberof Sale
     */
    cost_plan_name?: string;
    /**
     * 
     * @type {Array<CostOption>}
     * @memberof Sale
     */
    cost_plan_options?: Array<CostOption>;
    /**
     * 
     * @type {string}
     * @memberof Sale
     */
    created_by_user_name?: string;
    /**
     * 
     * @type {string}
     * @memberof Sale
     */
    created_by_user_email?: string;
    /**
     * 
     * @type {number}
     * @memberof Sale
     */
    created_at_unix_time?: number;
    /**
     * 
     * @type {number}
     * @memberof Sale
     */
    created_at_time?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Sale
     */
    pre_capture?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Sale
     */
    settlement_status_cancellation?: SaleSettlementStatusCancellationEnum;
    /**
     * 
     * @type {string}
     * @memberof Sale
     */
    settlement_day_cancellation?: string;
    /**
     * 
     * @type {SettlementBatch}
     * @memberof Sale
     */
    settlement_batch_cancellation?: SettlementBatch;
    /**
     * 
     * @type {string}
     * @memberof Sale
     */
    settlement_batch_cancellation_id?: string;
    /**
     * 
     * @type {SettlementBatch}
     * @memberof Sale
     */
    settlement_batch_retry?: SettlementBatch;
    /**
     * 
     * @type {string}
     * @memberof Sale
     */
    settlement_batch_retry_id?: string;
    /**
     * 
     * @type {string}
     * @memberof Sale
     */
    settlement_batch_retry_status?: SaleSettlementBatchRetryStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Sale
     */
    settlement_batch_retry_day?: string;
    /**
     * 
     * @type {string}
     * @memberof Sale
     */
    payment_day?: string;
    /**
     * 
     * @type {number}
     * @memberof Sale
     */
    payment_day_time?: number;
    /**
     * 
     * @type {number}
     * @memberof Sale
     */
    order_number: number;
    /**
     * 
     * @type {boolean}
     * @memberof Sale
     */
    has_split_rules?: boolean;
    /**
     * 
     * @type {SplitRules}
     * @memberof Sale
     */
    split_rules?: SplitRules;
    /**
     * 
     * @type {string}
     * @memberof Sale
     */
    split_rules_id?: string;
    /**
     * 
     * @type {string}
     * @memberof Sale
     */
    metadata?: string;
    /**
     * 
     * @type {string}
     * @memberof Sale
     */
    card_number?: string;
    /**
     * 
     * @type {string}
     * @memberof Sale
     */
    card_brand?: string;
    /**
     * 
     * @type {string}
     * @memberof Sale
     */
    card_holder_name?: string;
    /**
     * 
     * @type {string}
     * @memberof Sale
     */
    success_return_url?: string;
    /**
     * 
     * @type {string}
     * @memberof Sale
     */
    error_return_url?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Sale
     */
    confirmation_required?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof Sale
     */
    confirmation_day?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof Sale
     */
    risk_active?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Sale
     */
    is_capture?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Sale
     */
    split_description?: string;
    /**
     * 
     * @type {string}
     * @memberof Sale
     */
    extract_identification?: string;
    /**
     * 
     * @type {string}
     * @memberof Sale
     */
    terminal_id?: string;
    /**
     * 
     * @type {string}
     * @memberof Sale
     */
    service_id?: string;
    /**
     * 
     * @type {number}
     * @memberof Sale
     */
    spread?: number;
    /**
     * 
     * @type {string}
     * @memberof Sale
     */
    supportResponsible?: string;
    /**
     * 
     * @type {string}
     * @memberof Sale
     */
    commercialResponsible?: string;
    /**
     * 
     * @type {string}
     * @memberof Sale
     */
    qrcode_data?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Sale
     */
    three_ds_active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Sale
     */
    celcoin_id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Sale
     */
    write_off_file_generated?: boolean;
    /**
     * 
     * @type {Celcoin}
     * @memberof Sale
     */
    celcoin?: Celcoin;
    /**
     * 
     * @type {boolean}
     * @memberof Sale
     */
    registered_omie?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof Sale
     */
    expires_in?: Date;
    /**
     * 
     * @type {string}
     * @memberof Sale
     */
    refused_reason?: string;
}

/**
* @export
* @enum {string}
*/
export enum SaleAcquirerCodeEnum {
    Zoop = 'zoop',
    Adyen = 'adyen',
    Bradesco = 'bradesco',
    Itau = 'itau',
    BtgDigital = 'btg_digital',
    Rede = 'rede',
    Btg = 'btg'
}/**
* @export
* @enum {string}
*/
export enum SaleStatusEnum {
    Pending = 'pending',
    PendingCapture = 'pending_capture',
    InProgressCapture = 'in_progress_capture',
    Succeeded = 'succeeded',
    Canceled = 'canceled',
    PendingCancel = 'pending_cancel',
    Abort = 'abort',
    Failed = 'failed',
    WaitingConfirmation = 'waiting_confirmation',
    Expired = 'expired',
    WaitingCashIn = 'waiting_cashIn',
    Refused = 'refused'
}/**
* @export
* @enum {string}
*/
export enum SaleSettlementStatusEnum {
    Pending = 'pending',
    Paid = 'paid',
    Sent = 'sent',
    PendingCancel = 'pending_cancel',
    Canceled = 'canceled',
    Failed = 'failed',
    Resent = 'resent'
}/**
* @export
* @enum {string}
*/
export enum SaleFormPaymentEnum {
    Credit = 'credit',
    Debit = 'debit',
    Billet = 'billet',
    Pix = 'pix',
    Pending = 'pending',
    PixPos = 'pix_pos'
}/**
* @export
* @enum {string}
*/
export enum SaleTypeEnum {
    Boleto = 'boleto',
    Pos = 'pos',
    PaymentLink = 'payment_link',
    ProtestLink = 'protest_link',
    Online = 'online',
    AutoGeneratePaymentLink = 'auto_generate_payment_link',
    AutoGenerateProtestLink = 'auto_generate_protest_link',
    BilletCode = 'billet_code',
    BillPayment = 'bill_payment',
    Digital = 'digital'
}/**
* @export
* @enum {string}
*/
export enum SaleSettlementStatusCancellationEnum {
    Pending = 'pending',
    Paid = 'paid',
    Sent = 'sent',
    PendingCancel = 'pending_cancel',
    Canceled = 'canceled',
    Failed = 'failed',
    Resent = 'resent'
}/**
* @export
* @enum {string}
*/
export enum SaleSettlementBatchRetryStatusEnum {
    Pending = 'pending',
    Paid = 'paid',
    Sent = 'sent',
    PendingCancel = 'pending_cancel',
    Canceled = 'canceled',
    Failed = 'failed',
    Resent = 'resent'
}

export function SaleFromJSON(json: any): Sale {
    return SaleFromJSONTyped(json, false);
}

export function SaleFromJSONTyped(json: any, ignoreDiscriminator: boolean): Sale {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'description': json['description'],
        'protocol': !exists(json, 'protocol') ? undefined : json['protocol'],
        'acquirer_reference': !exists(json, 'acquirer_reference') ? undefined : json['acquirer_reference'],
        'acquirer_code': !exists(json, 'acquirer_code') ? undefined : json['acquirer_code'],
        'acquirer_document': !exists(json, 'acquirer_document') ? undefined : json['acquirer_document'],
        'created_at': !exists(json, 'created_at') ? undefined : json['created_at'],
        'updated_at': !exists(json, 'updated_at') ? undefined : json['updated_at'],
        'original_amount': json['original_amount'],
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'fee': !exists(json, 'fee') ? undefined : json['fee'],
        'absorb_costs': !exists(json, 'absorb_costs') ? undefined : json['absorb_costs'],
        'status': json['status'],
        'settlement_status': !exists(json, 'settlement_status') ? undefined : json['settlement_status'],
        'form_payment': !exists(json, 'form_payment') ? undefined : json['form_payment'],
        'type': json['type'],
        'seller': SellerFromJSON(json['seller']),
        'merchant': MerchantFromJSON(json['merchant']),
        'sale_plan': SalePlanFromJSON(json['sale_plan']),
        'cost_plan': CostPlanFromJSON(json['cost_plan']),
        'settlement_day': !exists(json, 'settlement_day') ? undefined : json['settlement_day'],
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
        'payment_type': !exists(json, 'payment_type') ? undefined : PaymentTypeFromJSON(json['payment_type']),
        'payment_type_id': !exists(json, 'payment_type_id') ? undefined : json['payment_type_id'],
        'payer': !exists(json, 'payer') ? undefined : PayerFromJSON(json['payer']),
        'payer_id': !exists(json, 'payer_id') ? undefined : json['payer_id'],
        'settlement_batch': !exists(json, 'settlement_batch') ? undefined : SettlementBatchFromJSON(json['settlement_batch']),
        'settlement_batch_id': !exists(json, 'settlement_batch_id') ? undefined : json['settlement_batch_id'],
        'billet': !exists(json, 'billet') ? undefined : BilletFromJSON(json['billet']),
        'billet_id': !exists(json, 'billet_id') ? undefined : json['billet_id'],
        'is_summed': !exists(json, 'is_summed') ? undefined : json['is_summed'],
        'is_visible': !exists(json, 'is_visible') ? undefined : json['is_visible'],
        'installments': !exists(json, 'installments') ? undefined : json['installments'],
        'seller_id': !exists(json, 'seller_id') ? undefined : json['seller_id'],
        'seller_name': !exists(json, 'seller_name') ? undefined : json['seller_name'],
        'seller_document': !exists(json, 'seller_document') ? undefined : json['seller_document'],
        'seller_description': json['seller_description'],
        'merchant_id': !exists(json, 'merchant_id') ? undefined : json['merchant_id'],
        'merchant_name': !exists(json, 'merchant_name') ? undefined : json['merchant_name'],
        'merchant_document': !exists(json, 'merchant_document') ? undefined : json['merchant_document'],
        'payment_type_code': !exists(json, 'payment_type_code') ? undefined : json['payment_type_code'],
        'payment_type_description': !exists(json, 'payment_type_description') ? undefined : json['payment_type_description'],
        'payment_type_installments': !exists(json, 'payment_type_installments') ? undefined : json['payment_type_installments'],
        'payer_name': !exists(json, 'payer_name') ? undefined : json['payer_name'],
        'payer_email': !exists(json, 'payer_email') ? undefined : json['payer_email'],
        'payer_document': !exists(json, 'payer_document') ? undefined : json['payer_document'],
        'payer_phone': !exists(json, 'payer_phone') ? undefined : json['payer_phone'],
        'payer_address': !exists(json, 'payer_address') ? undefined : GetSalePayerAddressDtoFromJSON(json['payer_address']),
        'sale_plan_id': !exists(json, 'sale_plan_id') ? undefined : json['sale_plan_id'],
        'sale_plan_name': !exists(json, 'sale_plan_name') ? undefined : json['sale_plan_name'],
        'cost_plan_name': !exists(json, 'cost_plan_name') ? undefined : json['cost_plan_name'],
        'cost_plan_options': !exists(json, 'cost_plan_options') ? undefined : ((json['cost_plan_options'] as Array<any>).map(CostOptionFromJSON)),
        'created_by_user_name': !exists(json, 'created_by_user_name') ? undefined : json['created_by_user_name'],
        'created_by_user_email': !exists(json, 'created_by_user_email') ? undefined : json['created_by_user_email'],
        'created_at_unix_time': !exists(json, 'created_at_unix_time') ? undefined : json['created_at_unix_time'],
        'created_at_time': !exists(json, 'created_at_time') ? undefined : json['created_at_time'],
        'pre_capture': !exists(json, 'pre_capture') ? undefined : json['pre_capture'],
        'settlement_status_cancellation': !exists(json, 'settlement_status_cancellation') ? undefined : json['settlement_status_cancellation'],
        'settlement_day_cancellation': !exists(json, 'settlement_day_cancellation') ? undefined : json['settlement_day_cancellation'],
        'settlement_batch_cancellation': !exists(json, 'settlement_batch_cancellation') ? undefined : SettlementBatchFromJSON(json['settlement_batch_cancellation']),
        'settlement_batch_cancellation_id': !exists(json, 'settlement_batch_cancellation_id') ? undefined : json['settlement_batch_cancellation_id'],
        'settlement_batch_retry': !exists(json, 'settlement_batch_retry') ? undefined : SettlementBatchFromJSON(json['settlement_batch_retry']),
        'settlement_batch_retry_id': !exists(json, 'settlement_batch_retry_id') ? undefined : json['settlement_batch_retry_id'],
        'settlement_batch_retry_status': !exists(json, 'settlement_batch_retry_status') ? undefined : json['settlement_batch_retry_status'],
        'settlement_batch_retry_day': !exists(json, 'settlement_batch_retry_day') ? undefined : json['settlement_batch_retry_day'],
        'payment_day': !exists(json, 'payment_day') ? undefined : json['payment_day'],
        'payment_day_time': !exists(json, 'payment_day_time') ? undefined : json['payment_day_time'],
        'order_number': json['order_number'],
        'has_split_rules': !exists(json, 'has_split_rules') ? undefined : json['has_split_rules'],
        'split_rules': !exists(json, 'split_rules') ? undefined : SplitRulesFromJSON(json['split_rules']),
        'split_rules_id': !exists(json, 'split_rules_id') ? undefined : json['split_rules_id'],
        'metadata': !exists(json, 'metadata') ? undefined : json['metadata'],
        'card_number': !exists(json, 'card_number') ? undefined : json['card_number'],
        'card_brand': !exists(json, 'card_brand') ? undefined : json['card_brand'],
        'card_holder_name': !exists(json, 'card_holder_name') ? undefined : json['card_holder_name'],
        'success_return_url': !exists(json, 'success_return_url') ? undefined : json['success_return_url'],
        'error_return_url': !exists(json, 'error_return_url') ? undefined : json['error_return_url'],
        'confirmation_required': !exists(json, 'confirmation_required') ? undefined : json['confirmation_required'],
        'confirmation_day': !exists(json, 'confirmation_day') ? undefined : (new Date(json['confirmation_day'])),
        'risk_active': !exists(json, 'risk_active') ? undefined : json['risk_active'],
        'is_capture': !exists(json, 'is_capture') ? undefined : json['is_capture'],
        'split_description': !exists(json, 'split_description') ? undefined : json['split_description'],
        'extract_identification': !exists(json, 'extract_identification') ? undefined : json['extract_identification'],
        'terminal_id': !exists(json, 'terminal_id') ? undefined : json['terminal_id'],
        'service_id': !exists(json, 'service_id') ? undefined : json['service_id'],
        'spread': !exists(json, 'spread') ? undefined : json['spread'],
        'supportResponsible': !exists(json, 'supportResponsible') ? undefined : json['supportResponsible'],
        'commercialResponsible': !exists(json, 'commercialResponsible') ? undefined : json['commercialResponsible'],
        'qrcode_data': !exists(json, 'qrcode_data') ? undefined : json['qrcode_data'],
        'three_ds_active': !exists(json, 'three_ds_active') ? undefined : json['three_ds_active'],
        'celcoin_id': !exists(json, 'celcoin_id') ? undefined : json['celcoin_id'],
        'write_off_file_generated': !exists(json, 'write_off_file_generated') ? undefined : json['write_off_file_generated'],
        'celcoin': !exists(json, 'celcoin') ? undefined : CelcoinFromJSON(json['celcoin']),
        'registered_omie': !exists(json, 'registered_omie') ? undefined : json['registered_omie'],
        'expires_in': !exists(json, 'expires_in') ? undefined : (new Date(json['expires_in'])),
        'refused_reason': !exists(json, 'refused_reason') ? undefined : json['refused_reason'],
    };
}

export function SaleToJSON(value?: Sale | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'description': value.description,
        'protocol': value.protocol,
        'acquirer_reference': value.acquirer_reference,
        'acquirer_code': value.acquirer_code,
        'acquirer_document': value.acquirer_document,
        'created_at': value.created_at,
        'updated_at': value.updated_at,
        'original_amount': value.original_amount,
        'amount': value.amount,
        'fee': value.fee,
        'absorb_costs': value.absorb_costs,
        'status': value.status,
        'settlement_status': value.settlement_status,
        'form_payment': value.form_payment,
        'type': value.type,
        'seller': SellerToJSON(value.seller),
        'merchant': MerchantToJSON(value.merchant),
        'sale_plan': SalePlanToJSON(value.sale_plan),
        'cost_plan': CostPlanToJSON(value.cost_plan),
        'settlement_day': value.settlement_day,
        'user': UserToJSON(value.user),
        'payment_type': PaymentTypeToJSON(value.payment_type),
        'payment_type_id': value.payment_type_id,
        'payer': PayerToJSON(value.payer),
        'payer_id': value.payer_id,
        'settlement_batch': SettlementBatchToJSON(value.settlement_batch),
        'settlement_batch_id': value.settlement_batch_id,
        'billet': BilletToJSON(value.billet),
        'billet_id': value.billet_id,
        'is_summed': value.is_summed,
        'is_visible': value.is_visible,
        'installments': value.installments,
        'seller_id': value.seller_id,
        'seller_name': value.seller_name,
        'seller_document': value.seller_document,
        'seller_description': value.seller_description,
        'merchant_id': value.merchant_id,
        'merchant_name': value.merchant_name,
        'merchant_document': value.merchant_document,
        'payment_type_code': value.payment_type_code,
        'payment_type_description': value.payment_type_description,
        'payment_type_installments': value.payment_type_installments,
        'payer_name': value.payer_name,
        'payer_email': value.payer_email,
        'payer_document': value.payer_document,
        'payer_phone': value.payer_phone,
        'payer_address': GetSalePayerAddressDtoToJSON(value.payer_address),
        'sale_plan_id': value.sale_plan_id,
        'sale_plan_name': value.sale_plan_name,
        'cost_plan_name': value.cost_plan_name,
        'cost_plan_options': value.cost_plan_options === undefined ? undefined : ((value.cost_plan_options as Array<any>).map(CostOptionToJSON)),
        'created_by_user_name': value.created_by_user_name,
        'created_by_user_email': value.created_by_user_email,
        'created_at_unix_time': value.created_at_unix_time,
        'created_at_time': value.created_at_time,
        'pre_capture': value.pre_capture,
        'settlement_status_cancellation': value.settlement_status_cancellation,
        'settlement_day_cancellation': value.settlement_day_cancellation,
        'settlement_batch_cancellation': SettlementBatchToJSON(value.settlement_batch_cancellation),
        'settlement_batch_cancellation_id': value.settlement_batch_cancellation_id,
        'settlement_batch_retry': SettlementBatchToJSON(value.settlement_batch_retry),
        'settlement_batch_retry_id': value.settlement_batch_retry_id,
        'settlement_batch_retry_status': value.settlement_batch_retry_status,
        'settlement_batch_retry_day': value.settlement_batch_retry_day,
        'payment_day': value.payment_day,
        'payment_day_time': value.payment_day_time,
        'order_number': value.order_number,
        'has_split_rules': value.has_split_rules,
        'split_rules': SplitRulesToJSON(value.split_rules),
        'split_rules_id': value.split_rules_id,
        'metadata': value.metadata,
        'card_number': value.card_number,
        'card_brand': value.card_brand,
        'card_holder_name': value.card_holder_name,
        'success_return_url': value.success_return_url,
        'error_return_url': value.error_return_url,
        'confirmation_required': value.confirmation_required,
        'confirmation_day': value.confirmation_day === undefined ? undefined : (value.confirmation_day.toISOString()),
        'risk_active': value.risk_active,
        'is_capture': value.is_capture,
        'split_description': value.split_description,
        'extract_identification': value.extract_identification,
        'terminal_id': value.terminal_id,
        'service_id': value.service_id,
        'spread': value.spread,
        'supportResponsible': value.supportResponsible,
        'commercialResponsible': value.commercialResponsible,
        'qrcode_data': value.qrcode_data,
        'three_ds_active': value.three_ds_active,
        'celcoin_id': value.celcoin_id,
        'write_off_file_generated': value.write_off_file_generated,
        'celcoin': CelcoinToJSON(value.celcoin),
        'registered_omie': value.registered_omie,
        'expires_in': value.expires_in === undefined ? undefined : (value.expires_in.toISOString()),
        'refused_reason': value.refused_reason,
    };
}


