/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetPartnerEnterpriseAddressInformationItemsDto
 */
export interface GetPartnerEnterpriseAddressInformationItemsDto {
    /**
     * 
     * @type {string}
     * @memberof GetPartnerEnterpriseAddressInformationItemsDto
     */
    type: GetPartnerEnterpriseAddressInformationItemsDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof GetPartnerEnterpriseAddressInformationItemsDto
     */
    zipCode: string;
    /**
     * 
     * @type {string}
     * @memberof GetPartnerEnterpriseAddressInformationItemsDto
     */
    country: string;
    /**
     * 
     * @type {string}
     * @memberof GetPartnerEnterpriseAddressInformationItemsDto
     */
    state: string;
    /**
     * 
     * @type {string}
     * @memberof GetPartnerEnterpriseAddressInformationItemsDto
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof GetPartnerEnterpriseAddressInformationItemsDto
     */
    street: string;
    /**
     * 
     * @type {string}
     * @memberof GetPartnerEnterpriseAddressInformationItemsDto
     */
    number: string;
    /**
     * 
     * @type {string}
     * @memberof GetPartnerEnterpriseAddressInformationItemsDto
     */
    complement?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPartnerEnterpriseAddressInformationItemsDto
     */
    neighborhood: string;
}

/**
* @export
* @enum {string}
*/
export enum GetPartnerEnterpriseAddressInformationItemsDtoTypeEnum {
    Residencial = 'RESIDENCIAL',
    Comercial = 'COMERCIAL',
    Outros = 'OUTROS',
    Naturalidade = 'NATURALIDADE'
}

export function GetPartnerEnterpriseAddressInformationItemsDtoFromJSON(json: any): GetPartnerEnterpriseAddressInformationItemsDto {
    return GetPartnerEnterpriseAddressInformationItemsDtoFromJSONTyped(json, false);
}

export function GetPartnerEnterpriseAddressInformationItemsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetPartnerEnterpriseAddressInformationItemsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'zipCode': json['zipCode'],
        'country': json['country'],
        'state': json['state'],
        'city': json['city'],
        'street': json['street'],
        'number': json['number'],
        'complement': !exists(json, 'complement') ? undefined : json['complement'],
        'neighborhood': json['neighborhood'],
    };
}

export function GetPartnerEnterpriseAddressInformationItemsDtoToJSON(value?: GetPartnerEnterpriseAddressInformationItemsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'zipCode': value.zipCode,
        'country': value.country,
        'state': value.state,
        'city': value.city,
        'street': value.street,
        'number': value.number,
        'complement': value.complement,
        'neighborhood': value.neighborhood,
    };
}


