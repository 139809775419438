import { Flex, GridItem, SimpleGrid } from '@chakra-ui/react';
import React from 'react';

import { subYears, endOfMonth, startOfMonth } from 'date-fns';

import FormControl from '../../../components/Form/FormControl';
import FormCpfInput from '../../../components/Form/FormCpfInput';
import FormErrorMessage from '../../../components/Form/FormErrorMessage';
import FormInput from '../../../components/Form/FormInput';
import FormLabel from '../../../components/Form/FormLabel';
import FormPhoneInput from '../../../components/Form/FormPhoneInput';
import FormSelect from '../../../components/Form/FormSelect';
import Title from '../../../components/Title';
import { months_of_year } from '../../../config/constants';

type OwnerFormTypes = {
	setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
	values: any;
};

const OwnerDataForm: React.FC<OwnerFormTypes> = ({ setFieldValue, values }) => {
	const getYearsToSelect = () => {
		const years_to_select: Array<number> = [];
		const initial_year = subYears(new Date(), 100).getFullYear();
		const final_year = subYears(new Date(), 18).getFullYear();

		for (let i = initial_year; i <= final_year; i += 1) {
			years_to_select.push(i);
		}
		return years_to_select.sort((a, b) => b - a);
	};

	const getDays = () => {
		const days: Array<number> = [];
		const initial_day = startOfMonth(new Date(values.owner_birth_year, values.owner_birth_month)).getDate();
		const final_day = endOfMonth(new Date(values.owner_birth_year, values.owner_birth_month)).getDate();

		for (let i = initial_day; i <= final_day; i += 1) {
			days.push(i);
		}
		return days;
	};

	return (
		<Flex direction='column' my='20px'>
			<Title id='pre-register-owner-title' textAlign='center' textDecoration='underline' mb='30px'>
				Dados do Responsável
			</Title>
			<SimpleGrid columns={12} color='gray.700' gap={1}>
				<GridItem colSpan={{ base: 12 }}>
					<FormControl id='pre-register-form-control-owner-name' isRequired width='100%'>
						<FormLabel id='pre-register-seller-owner-name-label' fontSize='md' fontWeight='bold'>
							Nome Oficial
						</FormLabel>
						<FormInput id='pre-register-seller-owner-name-form-input' name='owner_name' />
						<FormErrorMessage id='pre-register-seller-owner-name-form-error-text' name='owner_name' />
					</FormControl>
				</GridItem>
				<GridItem colSpan={{ base: 12 }}>
					<FormControl id='pre-register-form-control-owner-document' isRequired>
						<FormLabel id='pre-register-seller-owner-document-label' fontSize='md' fontWeight='bold'>
							CPF
						</FormLabel>
						<FormCpfInput id='pre-register-seller-owner-document-form-input' name='owner_document' />
						<FormErrorMessage id='pre-register-seller-owner-document-form-error-text' name='owner_document' />
					</FormControl>
				</GridItem>
				<GridItem colSpan={{ base: 12 }}>
					<FormLabel id='owner-birth-date-label' fontSize='md' fontWeight='bold'>
						Data de Nascimento
					</FormLabel>
				</GridItem>
				<GridItem colSpan={{ base: 12, xl: 4 }}>
					<FormLabel id='owner-birth-year-label'>Ano</FormLabel>
					<FormControl isRequired>
						<FormSelect id='owner-birth-year-select' name='owner_birth_year'>
							<option id='owner-birth-year-empty' value=''></option>
							{getYearsToSelect().map((year) => (
								<option id={`owner-birth-year-${year}`} key={year} value={year}>
									{year}
								</option>
							))}
						</FormSelect>
						<FormErrorMessage id='owner-birth-year-form-error-text' name='owner_birth_year' />
					</FormControl>
				</GridItem>
				<GridItem colSpan={{ base: 12, xl: 4 }}>
					<FormLabel id='owner-birth-month-label'>Mês</FormLabel>
					<FormControl isRequired>
						<FormSelect id='owner-birth-month-select' name='owner_birth_month'>
							<option id='owner-birth-month-empty' value=''></option>
							{months_of_year.map((month) => (
								<option id={`owner-birth-month-${month.value}`} key={month.key} value={month.value}>
									{month.text}
								</option>
							))}
						</FormSelect>
						<FormErrorMessage id='owner-birth-date-form-error-text' name='owner_birth_month' />
					</FormControl>
				</GridItem>
				<GridItem colSpan={{ base: 12, xl: 4 }}>
					<FormLabel id='owner-birth-day-label'>Dia</FormLabel>
					<FormControl isRequired>
						<FormSelect id='owner-birth-day-select' name='owner_birth_day'>
							<option id='owner-birth-day-empty' value=''></option>
							{getDays().map((day) => (
								<option id={`owner-birth-day-${day}`} key={day}>
									{day}
								</option>
							))}
						</FormSelect>
						<FormErrorMessage id='owner-mother-name-form-error-text' name='owner_birth_day' />
					</FormControl>
				</GridItem>
				<GridItem colSpan={{ base: 12 }}>
					<FormControl id='pre-register-form-control-owner-mother-name' isRequired width='100%'>
						<FormLabel id='pre-register-seller-owner-mother-name-label' fontSize='md' fontWeight='bold'>
							Filiação (Mãe)
						</FormLabel>
						<FormInput id='pre-register-seller-owner-mother-name-form-input' name='owner_mother_name' />
						<FormErrorMessage id='pre-register-seller-owner-mother-name-form-error-text' name='owner_mother_name' />
					</FormControl>
				</GridItem>
				<GridItem colSpan={{ base: 12 }}>
					<FormControl id='pre-register-phone'>
						<FormLabel id='settings-phone-label' fontSize='md' fontWeight='bold'>
							Telefone
						</FormLabel>
						<FormPhoneInput id='phone-form-input' name='phone' />
						<FormErrorMessage id='seller-phone-form-error-text' name='phone' />
					</FormControl>
				</GridItem>
				<GridItem colSpan={{ base: 12 }}>
					<FormControl id='pre-register-email'>
						<FormLabel id='pre-register-seller-email-label' fontSize='md' fontWeight='bold'>
							E-mail
						</FormLabel>
						<FormInput id='pre-register-seller-form-email-form-input' name='email' />
						<FormErrorMessage id='pre-register-seller-email-form-error-text' name='email' />
					</FormControl>
				</GridItem>
			</SimpleGrid>
		</Flex>
	);
};

export default OwnerDataForm;
