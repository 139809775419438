import React from 'react';
import {
	Popover,
	PopoverTrigger,
	PopoverContent,
	Image,
	PopoverArrow,
	PopoverCloseButton,
	PopoverBody,
	IconButton,
	Portal,
	useDisclosure,
	Box,
	Spinner,
} from '@chakra-ui/react';
import { ListSellerResponsibleSupportByIdRequest, SellersApi } from '../clients';
import { getApiAuthConfig } from '../services/api.service';
import { useToasts } from 'react-toast-notifications';
import { FaWhatsapp } from 'react-icons/fa';
import { logoUrl } from '../config/variables';

type ResponsibleSupport = {
	name: string;
	phone: string;
	is_active: boolean;
	type: string;
	url: string
};

const CustomPopover = ({ sellerId }) => {
	const { onOpen, onClose, isOpen } = useDisclosure();
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const apiConfig = getApiAuthConfig();
	const sellerApi = new SellersApi(apiConfig);
	const { addToast } = useToasts();

	const [initialValues, setInitalValues] = React.useState<ResponsibleSupport>({
		name: '',
		type: '',
		phone: '',
		is_active: true,
		url: ''
	});

	const getResponsible = async () => {
		setIsLoading(true);

		try {
			const getByIdParams: ListSellerResponsibleSupportByIdRequest = { sellerId: sellerId };

			const responsible = await sellerApi.listSellerResponsibleSupportById(getByIdParams);

			setInitalValues({
				name: responsible.name,
				type: responsible.type,
				phone: responsible.phone,
				is_active: responsible.is_active,
				url: responsible.url
			});

			setIsLoading(false);
		} catch (error) {
			addToast('Erro ao buscar Responsável', {
				appearance: 'error',
				autoDismiss: true,
			});

			setIsLoading(false);
		}
	};

	React.useEffect(() => {
		getResponsible();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleSupportClick = () => {
		window.open(
			`https://wa.me/55${initialValues.phone}?text=Olá,%20preciso%20de%20suporte%20com%20o%20Cart%20Express.%20Poderia%20me%20ajudar?`,
			'_blank'
		);
	};

	if (isLoading) {
		return (
			<Box position='fixed' bottom='0' right='0' m={4}>
				<Spinner
					id='custom-popover-is-loading-spinner'
					thickness='1px'
					speed='0.65s'
					emptyColor='gray.200'
					color='blue.500'
					size='sm'
					p={2}
					mt={4}
					mb={2}
				/>
			</Box>
		);
	}

	return (
		<>
			<Box position='fixed' bottom='0' right='0' m={4}>
				{initialValues.phone && (
					<Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
						<PopoverTrigger>
							<Box
								id='custom-popover-responsible-box-button'
								as='button'
								onClick={onOpen}
								w='60px'
								h='60px'
								borderRadius='50%'
								overflow='hidden'
								border='1px solid black'
								display='flex'
								alignItems='center'
								justifyContent='center'
							>
								<Image id='custom-popover-responsible-image' src={initialValues.url ? initialValues.url : logoUrl()} w='68px' h='68px' objectFit='cover' alt='Logo' />
							</Box>
						</PopoverTrigger>
						<Portal>
							<PopoverContent borderRadius='lg' mr='20px'>
								<PopoverArrow />
								<PopoverCloseButton />
								<PopoverBody mt='10px'>
									<p id='custom-popover-mensage-text'>
										Olá, sou <strong>{initialValues.name}</strong>, responsável pelo seu cartório. Se precisar de ajuda, clique no botão
										abaixo.
									</p>
									<IconButton
										id='custom-popover-whatsapp-icon-button'
										mt='10px'
										aria-label='WhatsApp'
										icon={<FaWhatsapp size={'30px'} />}
										onClick={handleSupportClick}
										borderRadius='22%'
										size='lg'
										w='44px'
										h='44px'
										bg='green.500'
										color='white'
										_hover={{ bg: 'green.600' }}
									/>
								</PopoverBody>
							</PopoverContent>
						</Portal>
					</Popover>
				)}
			</Box>
		</>
	);
};

export default CustomPopover;
