import { Flex, GridItem, Image, SimpleGrid, useBreakpointValue } from '@chakra-ui/react';
import React from 'react';

import { Formik } from 'formik';

import Button from '../../components/Button';
import Title from '../../components/Title';
import Text from '../../components/Text';
import FormControl from '../../components/Form/FormControl';
import FormLabel from '../../components/Form/FormLabel';
import FormSelect from '../../components/Form/FormSelect';
import FormErrorMessage from '../../components/Form/FormErrorMessage';
import FormCurrencyInput from '../../components/Form/FormCurrencyInput';
import OpenAccountAttachments from './components/OpenAccountAttachments';
import yup from '../../services/yup.service';

const OpenDigitalAccount: React.FC = () => {
	const isMobile = useBreakpointValue({ base: true, lg: false });

	const handleFormSubmit = async (values, isValid) => {};

	return (
		<Flex direction={['column', 'row']} height='100%' justifyContent='space-between'>
			<Flex maxH='100%' direction='column' alignItems='center' padding='30px' width='100%'>
				<Flex direction='column' width='100%' alignItems='center'>
					<Title id='onr-pre-register-title-1' textAlign='center' fontSize='xl'>
						DADOS PARA ABERTURA DE CONTA DIGITAL
					</Title>
				</Flex>
				<Flex direction='column' width='100%' alignItems='center'>
					<Formik validateOnMount initialValues={{}} onSubmit={handleFormSubmit} validationSchema={yup.object()}>
						{({ handleSubmit, setFieldValue, values, isValid }) => (
							<form onSubmit={handleSubmit} style={{ width: '100%'}}>
								<SimpleGrid columns={12} color='gray.700' gap={2}>
									<GridItem colSpan={{ base: 12 }}>
										<Text id='open-digital-legal-information-title' textAlign='center' fontSize='lg' color={'darkGrey'} mt='2'>
											Informações Legais
										</Text>
									</GridItem>
									<GridItem colSpan={{ base: 12, xl: 12 }}>
										<FormControl color={ 'darkGrey'} id='open-digital-account-legal-nature' isRequired>
											<FormLabel id='open-digital-account-legal-nature-label' color={'darkGrey'} fontSize='md' fontWeight='medium'>
												Natureza legal
											</FormLabel>
											<FormSelect id='open-digital-account-legal-nature-form-select' fontSize='md' name='legal_nature'>
												<option value='' />
												{Object.values(['EI', 'MEI']).map((legalNature) => (
													<option value={legalNature} key={legalNature}>
														{legalNature}
													</option>
												))}
											</FormSelect>
											<FormErrorMessage id='open-digital-account-legal-nature-form-error-text' name='legal_nature' />
										</FormControl>
									</GridItem>
									<GridItem colSpan={{ base: 12, xl: 12 }}>
										<FormLabel id='open-digital-account-patrimony-amount-label' color={'darkGray'} fontSize='md' fontWeight='medium'>
											Patrimônio total
										</FormLabel>
										<FormCurrencyInput id='open-digital-account-patrimony-amount-input' name='patrimony_amount' />
										<FormErrorMessage id='open-digital-account-patrimony-amount-form-error-text' name='patrimony_amount' />
									</GridItem>
									<GridItem colSpan={{ base: 12 }}>
										<Flex direction='column' mt='5'>
											<FormLabel id='open-digital-account-legal-nature-label' color={'darkGrey'} fontSize='md' fontWeight='medium'>
												Anexar Termo de Posse
											</FormLabel>
											<OpenAccountAttachments attachments={[]} changeAttachments={(files) => setFieldValue('attachments', files)} />
											<Button id='open-digital-account-submit-button' type='submit' disabled={!isValid} w='100%'>
												Enviar
											</Button>
										</Flex>
									</GridItem>
								</SimpleGrid>
							</form>
						)}
					</Formik>
				</Flex>
			</Flex>
			{!isMobile && (
				<Flex width='100%' justifyContent='flex-end'>
					<Image maxH='100%' src={require('../../assets/images/open-digital-account-2.jpeg')} />
				</Flex>
			)}
		</Flex>
	);
};

export default OpenDigitalAccount;
