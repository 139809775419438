/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateDigitalAuthorizationDto
 */
export interface CreateDigitalAuthorizationDto {
    /**
     * 
     * @type {string}
     * @memberof CreateDigitalAuthorizationDto
     */
    uri_path: string;
}

export function CreateDigitalAuthorizationDtoFromJSON(json: any): CreateDigitalAuthorizationDto {
    return CreateDigitalAuthorizationDtoFromJSONTyped(json, false);
}

export function CreateDigitalAuthorizationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateDigitalAuthorizationDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'uri_path': json['uri_path'],
    };
}

export function CreateDigitalAuthorizationDtoToJSON(value?: CreateDigitalAuthorizationDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'uri_path': value.uri_path,
    };
}


