const preRegisterSellerInitialValues = {
	name: '',
	cns: '',
	document: '',
	description: '',
	address: {
		zipcode: '',
		street: '',
		district: '',
		city: '',
		state: '',
		complement: '',
		number: '',
	},
	phone: '',
	secondary_phone: '',
	tertiary_phone: '',
	email: '',
	secondary_email: '',
	tertiary_email: '',
	erp: '',
	seller_assignment: '',
	owner_name: '',
	owner_document: '',
	owner_mother_name: '',
	owner_birth_year: '',
	owner_birth_month: '',
	owner_birth_day: '',
	active_debit: '',
	active_credit_1x: '',
	active_credit_2x_to_18x: '',
	active_billet: '',
	active_pix_pos: '',
	active_pix_online: '',
	pass_on_cost_debit: '',
	pass_on_cost_credit_1x: '',
	pass_on_cost_credit_2x_to_18x: '',
	pass_on_cost_billet: '',
	pass_on_cost_pix_pos: '',
	pass_on_cost_pix_online: '',
	confirm_information: false,
};

export default preRegisterSellerInitialValues;
