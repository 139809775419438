/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetPartnerEnterpriseDocumentsLinksDto,
    GetPartnerEnterpriseDocumentsLinksDtoFromJSON,
    GetPartnerEnterpriseDocumentsLinksDtoFromJSONTyped,
    GetPartnerEnterpriseDocumentsLinksDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetPartnerEnterpriseDocumentsItemsDto
 */
export interface GetPartnerEnterpriseDocumentsItemsDto {
    /**
     * 
     * @type {string}
     * @memberof GetPartnerEnterpriseDocumentsItemsDto
     */
    type: GetPartnerEnterpriseDocumentsItemsDtoTypeEnum;
    /**
     * 
     * @type {GetPartnerEnterpriseDocumentsLinksDto}
     * @memberof GetPartnerEnterpriseDocumentsItemsDto
     */
    _links: GetPartnerEnterpriseDocumentsLinksDto;
}

/**
* @export
* @enum {string}
*/
export enum GetPartnerEnterpriseDocumentsItemsDtoTypeEnum {
    ContratoSocial = 'CONTRATO_SOCIAL',
    Procuracao = 'PROCURACAO',
    EstatutoSocial = 'ESTATUTO_SOCIAL',
    RequerimentoEmpresario = 'REQUERIMENTO_EMPRESARIO',
    BalancoDre = 'BALANCO_DRE',
    AtaEleicaoDiretoria = 'ATA_ELEICAO_DIRETORIA',
    QuadroSocietario = 'QUADRO_SOCIETARIO',
    DocumentoFinanceiro = 'DOCUMENTO_FINANCEIRO',
    Ccmei = 'CCMEI'
}

export function GetPartnerEnterpriseDocumentsItemsDtoFromJSON(json: any): GetPartnerEnterpriseDocumentsItemsDto {
    return GetPartnerEnterpriseDocumentsItemsDtoFromJSONTyped(json, false);
}

export function GetPartnerEnterpriseDocumentsItemsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetPartnerEnterpriseDocumentsItemsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        '_links': GetPartnerEnterpriseDocumentsLinksDtoFromJSON(json['_links']),
    };
}

export function GetPartnerEnterpriseDocumentsItemsDtoToJSON(value?: GetPartnerEnterpriseDocumentsItemsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        '_links': GetPartnerEnterpriseDocumentsLinksDtoToJSON(value._links),
    };
}


