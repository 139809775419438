import { Flex, GridItem, SimpleGrid, Input } from '@chakra-ui/react';
import React from 'react';
import { useToasts } from 'react-toast-notifications';

import FormControl from '../../../components/Form/FormControl';
import FormErrorMessage from '../../../components/Form/FormErrorMessage';
import FormInput from '../../../components/Form/FormInput';
import FormLabel from '../../../components/Form/FormLabel';
import FormSelect from '../../../components/Form/FormSelect';
import Title from '../../../components/Title';
import { type_account } from '../../../config/constants';

type banksList = {
	ispb: string;
	name: string;
	code: number;
	fullName: string;
};

const BankAccountForm: React.FC = () => {
	const { addToast } = useToasts();

	const [banks, setBanks] = React.useState<Array<banksList>>([]);

	React.useEffect(() => {
		const getBanks = async () => {
			const data = await fetch('https://brasilapi.com.br/api/banks/v1');

			const jsonBanks = await data.json();

			setBanks(jsonBanks);
		};

		getBanks().catch(() => {
			addToast('Erro ao carregar os bancos', {
				autoDismiss: true,
				appearance: 'error',
			});
		});
	}, [addToast]);

	return (
		<Flex direction='column' my='20px'>
			<Title id='pre-register-bank-account-title' textAlign='center' textDecoration='underline' mb='30px'>
				Dados Bancários
			</Title>
			<SimpleGrid columns={{ base: 1, md: 6, xl: 12 }} gap={1}>
				<GridItem colSpan={{ base: 12, xl: 6 }}>
					<FormControl id='pre-register-bank-code' isRequired>
						<FormLabel id='pre-register-bank-account-code-label' fontSize='md' fontWeight='bold'>
							Banco
						</FormLabel>
						<FormSelect id='owner-birth-day-select' name='bank_account.bank_code'>
							<option value='' />
							{banks?.length &&
								banks.map((bank) => (
									<option value={bank.code} key={bank.code}>
										{bank.code} - {bank.fullName}
									</option>
								))}
						</FormSelect>
						<FormErrorMessage id='pre-register-bank-account-code-form-error-text' name='bank_account.bank_code' />
					</FormControl>
				</GridItem>
				<GridItem colSpan={{ base: 12, xl: 6 }}>
					<FormControl id='pre-register-bank-account-type-form-control' isRequired>
						<FormLabel id='pre-register-bank-account-type-form-label' fontSize='md' fontWeight='bold'>
							Tipo de conta
						</FormLabel>
						<FormSelect id='pre-register-bank-account-type-form-select' name='bank_account.type_account'>
							<option value='' />
							{type_account.map((type) => (
								<option value={type.value} key={type.value}>
									{type.text}
								</option>
							))}
						</FormSelect>
						<FormErrorMessage id='pre-register-bank-account-type-form-error-text' name='bank_account.type_account' />
					</FormControl>
				</GridItem>
				<GridItem colSpan={{ base: 12, xl: 8 }}>
					<FormControl id='pre-register-bank-agency-number-form-control' isRequired>
						<FormLabel id='pre-register-bank-agency-number-form-label' fontSize='md' fontWeight='bold'>
							Número da agência
						</FormLabel>
						<FormInput id='pre-register-bank-agency-number-form-input' as={Input} name='bank_account.agency_number' />
						<FormErrorMessage id='pre-register-bank-agency-number-form-error-text' name='bank_account.agency_number' />
					</FormControl>
				</GridItem>
				<GridItem colSpan={{ base: 12, xl: 4 }}>
					<FormControl id='pre-register-bank-agency-digit-form-control'>
						<FormLabel id='pre-register-bank-agency-digit-form-label' fontSize='md' fontWeight='bold'>
							Dígito
						</FormLabel>
						<FormInput id='pre-register-bank-agency-digit-form-input' as={Input} name='bank_account.agency_digit' />
						<FormErrorMessage id='pre-register-bank-agency-digit-form-error-text' name='bank_account.agency_digit' />
					</FormControl>
				</GridItem>
				<GridItem colSpan={{ base: 12, xl: 8 }}>
					<FormControl id='pre-register-bank-account-number-form-input' isRequired>
						<FormLabel id='pre-register-bank-account-number-form-label' fontSize='md' fontWeight='bold'>
							Número da conta
						</FormLabel>
						<FormInput id='pre-register-bank-account-number-form-input' as={Input} name='bank_account.account_number' />
						<FormErrorMessage id='pre-register-bank-account-number-form-error-text' name='bank_account.account_number' />
					</FormControl>
				</GridItem>
				<GridItem colSpan={{ base: 12, xl: 4 }}>
					<FormControl id='pre-register-bank-account-digit-form-control'>
						<FormLabel id='pre-register-bank-account-digit-form-label' fontSize='md' fontWeight='bold'>
							Dígito
						</FormLabel>
						<FormInput id='pre-register-bank-account-digit-form-input' as={Input} name='bank_account.account_digit' />
						<FormErrorMessage id='pre-register-bank-account-digit-form-error-text' name='bank_account.account_digit' />
					</FormControl>
				</GridItem>
				<GridItem colSpan={{ base: 12 }}>
					<FormControl id='pre-register-bank-pix-key-form'>
						<FormLabel id='pre-register-bank-pix-key-label' fontSize='md' fontWeight='bold'>
							Chave Pix
						</FormLabel>
						<FormInput id='pre-register-bank-pix-key-form-input' as={Input} name='bank_account.pix_key' />
						<FormErrorMessage id='pre-register-bank-pix-key-form-eror-text' name='bank_account.pix_key' />
					</FormControl>
				</GridItem>
			</SimpleGrid>
		</Flex>
	);
};

export default BankAccountForm;
