import { Text, TextProps } from '@chakra-ui/react';
import React from 'react';

import { useTheme } from 'contexts/ThemeProvider';

type TitleProps = TextProps & {
	children: string;
	fontSize?: string;
	id: string;
};

const Title: React.FC<TitleProps> = ({ children, fontSize = 'lg', id, ...props }: TitleProps) => {
	const { theme } = useTheme();

	return (
		<Text id={id} color={theme?.secondary_color || 'blackAlpha.600'} fontSize={fontSize} fontWeight='bold' {...props}>
			{children}
		</Text>
	);
};

export default Title;
