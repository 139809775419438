/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreateExportSettlementBatchSalesListDto,
    CreateExportSettlementBatchSalesListDtoFromJSON,
    CreateExportSettlementBatchSalesListDtoToJSON,
    CreateExportSettlementListDto,
    CreateExportSettlementListDtoFromJSON,
    CreateExportSettlementListDtoToJSON,
    GetFileExportRequestDto,
    GetFileExportRequestDtoFromJSON,
    GetFileExportRequestDtoToJSON,
    GetSettlementResponseDto,
    GetSettlementResponseDtoFromJSON,
    GetSettlementResponseDtoToJSON,
    PaginatedDto,
    PaginatedDtoFromJSON,
    PaginatedDtoToJSON,
    SettlementBatch,
    SettlementBatchFromJSON,
    SettlementBatchToJSON,
    UpdateSettlementBatchDTO,
    UpdateSettlementBatchDTOFromJSON,
    UpdateSettlementBatchDTOToJSON,
} from '../models';

export interface ConcilExportRequestRequest {
    createExportSettlementListDto: CreateExportSettlementListDto;
}

export interface CreateSettlementBatchListExportRequestRequest {
    createExportSettlementListDto: CreateExportSettlementListDto;
}

export interface CreateSettlementBatchSalesExportRequestRequest {
    settlementBatchId: string;
    createExportSettlementBatchSalesListDto: CreateExportSettlementBatchSalesListDto;
}

export interface CreateTransactionsListExportRequestRequest {
    createExportSettlementListDto: CreateExportSettlementListDto;
}

export interface ListAllSettlementRequest {
    startDate?: string;
    endDate?: string;
    id?: string;
    status?: ListAllSettlementStatusEnum;
    sellerId?: string;
    type?: ListAllSettlementTypeEnum;
    limit?: number;
    currentPage?: number;
    orderByField?: ListAllSettlementOrderByFieldEnum;
    orderByValue?: ListAllSettlementOrderByValueEnum;
}

export interface ListAllSettlementDetailedRequest {
    startDate?: string;
    endDate?: string;
    id?: string;
    status?: ListAllSettlementDetailedStatusEnum;
    type?: ListAllSettlementDetailedTypeEnum;
    limit?: number;
    currentPage?: number;
    orderByField?: ListAllSettlementDetailedOrderByFieldEnum;
    orderByValue?: ListAllSettlementDetailedOrderByValueEnum;
}

export interface ListSellerSettlementRequest {
    sellerId: string;
    startDate?: string;
    endDate?: string;
    id?: string;
    status?: ListSellerSettlementStatusEnum;
    type?: ListSellerSettlementTypeEnum;
    limit?: number;
    currentPage?: number;
    orderByField?: ListSellerSettlementOrderByFieldEnum;
    orderByValue?: ListSellerSettlementOrderByValueEnum;
}

export interface NoPaginatedListAllSettlementDetailedWithRequest {
    startDate?: string;
    endDate?: string;
    id?: string;
    status?: NoPaginatedListAllSettlementDetailedWithStatusEnum;
    type?: NoPaginatedListAllSettlementDetailedWithTypeEnum;
    limit?: number;
    currentPage?: number;
    orderByField?: NoPaginatedListAllSettlementDetailedWithOrderByFieldEnum;
    orderByValue?: NoPaginatedListAllSettlementDetailedWithOrderByValueEnum;
    sellerId?: string;
    merchantId?: string;
}

export interface SettlementBatchControllerGetSettlementBatchRequest {
    limit: number;
    currentPage: number;
    settlementBatchId: string;
    startDate?: string;
    endDate?: string;
    sort?: SettlementBatchControllerGetSettlementBatchSortEnum;
    settlementStatus?: Array<SettlementBatchControllerGetSettlementBatchSettlementStatusEnum>;
    description?: string;
    amount?: number;
    originalAmount?: number;
    type?: Array<SettlementBatchControllerGetSettlementBatchTypeEnum>;
    name?: string;
    formPayment?: Array<SettlementBatchControllerGetSettlementBatchFormPaymentEnum>;
}

export interface SettlementBatchControllerUpdateSettlementBatchRequest {
    settlementBatchId: string;
    updateSettlementBatchDTO: UpdateSettlementBatchDTO;
}

/**
 * 
 */
export class SettlementsBatchesApi extends runtime.BaseAPI {

    /**
     * Gerar uma requisição de exportação das vendas dos históricos de liquidações para conciliação
     */
    async concilExportRequestRaw(requestParameters: ConcilExportRequestRequest): Promise<runtime.ApiResponse<GetFileExportRequestDto>> {
        if (requestParameters.createExportSettlementListDto === null || requestParameters.createExportSettlementListDto === undefined) {
            throw new runtime.RequiredError('createExportSettlementListDto','Required parameter requestParameters.createExportSettlementListDto was null or undefined when calling concilExportRequest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/settlement/list/transactions/concil/export/requests`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateExportSettlementListDtoToJSON(requestParameters.createExportSettlementListDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetFileExportRequestDtoFromJSON(jsonValue));
    }

    /**
     * Gerar uma requisição de exportação das vendas dos históricos de liquidações para conciliação
     */
    async concilExportRequest(requestParameters: ConcilExportRequestRequest): Promise<GetFileExportRequestDto> {
        const response = await this.concilExportRequestRaw(requestParameters);
        return await response.value();
    }

    /**
     * Gerar uma requisição de exportação dos históricos de liquidação
     */
    async createSettlementBatchListExportRequestRaw(requestParameters: CreateSettlementBatchListExportRequestRequest): Promise<runtime.ApiResponse<GetFileExportRequestDto>> {
        if (requestParameters.createExportSettlementListDto === null || requestParameters.createExportSettlementListDto === undefined) {
            throw new runtime.RequiredError('createExportSettlementListDto','Required parameter requestParameters.createExportSettlementListDto was null or undefined when calling createSettlementBatchListExportRequest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/settlement/list/export/requests`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateExportSettlementListDtoToJSON(requestParameters.createExportSettlementListDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetFileExportRequestDtoFromJSON(jsonValue));
    }

    /**
     * Gerar uma requisição de exportação dos históricos de liquidação
     */
    async createSettlementBatchListExportRequest(requestParameters: CreateSettlementBatchListExportRequestRequest): Promise<GetFileExportRequestDto> {
        const response = await this.createSettlementBatchListExportRequestRaw(requestParameters);
        return await response.value();
    }

    /**
     * Gerar uma requisição de exportação da lista das vendas de um lote de liquidação
     */
    async createSettlementBatchSalesExportRequestRaw(requestParameters: CreateSettlementBatchSalesExportRequestRequest): Promise<runtime.ApiResponse<GetFileExportRequestDto>> {
        if (requestParameters.settlementBatchId === null || requestParameters.settlementBatchId === undefined) {
            throw new runtime.RequiredError('settlementBatchId','Required parameter requestParameters.settlementBatchId was null or undefined when calling createSettlementBatchSalesExportRequest.');
        }

        if (requestParameters.createExportSettlementBatchSalesListDto === null || requestParameters.createExportSettlementBatchSalesListDto === undefined) {
            throw new runtime.RequiredError('createExportSettlementBatchSalesListDto','Required parameter requestParameters.createExportSettlementBatchSalesListDto was null or undefined when calling createSettlementBatchSalesExportRequest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/settlement/{settlement_batch_id}/list/export/requests`.replace(`{${"settlement_batch_id"}}`, encodeURIComponent(String(requestParameters.settlementBatchId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateExportSettlementBatchSalesListDtoToJSON(requestParameters.createExportSettlementBatchSalesListDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetFileExportRequestDtoFromJSON(jsonValue));
    }

    /**
     * Gerar uma requisição de exportação da lista das vendas de um lote de liquidação
     */
    async createSettlementBatchSalesExportRequest(requestParameters: CreateSettlementBatchSalesExportRequestRequest): Promise<GetFileExportRequestDto> {
        const response = await this.createSettlementBatchSalesExportRequestRaw(requestParameters);
        return await response.value();
    }

    /**
     * Gerar uma requisição de exportação das vendas dos históricos de liquidações
     */
    async createTransactionsListExportRequestRaw(requestParameters: CreateTransactionsListExportRequestRequest): Promise<runtime.ApiResponse<GetFileExportRequestDto>> {
        if (requestParameters.createExportSettlementListDto === null || requestParameters.createExportSettlementListDto === undefined) {
            throw new runtime.RequiredError('createExportSettlementListDto','Required parameter requestParameters.createExportSettlementListDto was null or undefined when calling createTransactionsListExportRequest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/settlement/list/transactions/export/requests`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateExportSettlementListDtoToJSON(requestParameters.createExportSettlementListDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetFileExportRequestDtoFromJSON(jsonValue));
    }

    /**
     * Gerar uma requisição de exportação das vendas dos históricos de liquidações
     */
    async createTransactionsListExportRequest(requestParameters: CreateTransactionsListExportRequestRequest): Promise<GetFileExportRequestDto> {
        const response = await this.createTransactionsListExportRequestRaw(requestParameters);
        return await response.value();
    }

    /**
     * Listar liquidações
     */
    async listAllSettlementRaw(requestParameters: ListAllSettlementRequest): Promise<runtime.ApiResponse<PaginatedDto>> {
        const queryParameters: any = {};

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = requestParameters.startDate;
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = requestParameters.endDate;
        }

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.sellerId !== undefined) {
            queryParameters['sellerId'] = requestParameters.sellerId;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.currentPage !== undefined) {
            queryParameters['current_page'] = requestParameters.currentPage;
        }

        if (requestParameters.orderByField !== undefined) {
            queryParameters['order_by_field'] = requestParameters.orderByField;
        }

        if (requestParameters.orderByValue !== undefined) {
            queryParameters['order_by_value'] = requestParameters.orderByValue;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/settlement`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedDtoFromJSON(jsonValue));
    }

    /**
     * Listar liquidações
     */
    async listAllSettlement(requestParameters: ListAllSettlementRequest): Promise<PaginatedDto> {
        const response = await this.listAllSettlementRaw(requestParameters);
        return await response.value();
    }

    /**
     * Listar todas as liquidações detalhadas
     */
    async listAllSettlementDetailedRaw(requestParameters: ListAllSettlementDetailedRequest): Promise<runtime.ApiResponse<PaginatedDto>> {
        const queryParameters: any = {};

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = requestParameters.startDate;
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = requestParameters.endDate;
        }

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.currentPage !== undefined) {
            queryParameters['current_page'] = requestParameters.currentPage;
        }

        if (requestParameters.orderByField !== undefined) {
            queryParameters['order_by_field'] = requestParameters.orderByField;
        }

        if (requestParameters.orderByValue !== undefined) {
            queryParameters['order_by_value'] = requestParameters.orderByValue;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/settlement/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedDtoFromJSON(jsonValue));
    }

    /**
     * Listar todas as liquidações detalhadas
     */
    async listAllSettlementDetailed(requestParameters: ListAllSettlementDetailedRequest): Promise<PaginatedDto> {
        const response = await this.listAllSettlementDetailedRaw(requestParameters);
        return await response.value();
    }

    /**
     * Listar liquidações separadas por seller
     */
    async listSellerSettlementRaw(requestParameters: ListSellerSettlementRequest): Promise<runtime.ApiResponse<PaginatedDto>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling listSellerSettlement.');
        }

        const queryParameters: any = {};

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = requestParameters.startDate;
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = requestParameters.endDate;
        }

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.currentPage !== undefined) {
            queryParameters['current_page'] = requestParameters.currentPage;
        }

        if (requestParameters.orderByField !== undefined) {
            queryParameters['order_by_field'] = requestParameters.orderByField;
        }

        if (requestParameters.orderByValue !== undefined) {
            queryParameters['order_by_value'] = requestParameters.orderByValue;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/settlement/{sellerId}/list`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedDtoFromJSON(jsonValue));
    }

    /**
     * Listar liquidações separadas por seller
     */
    async listSellerSettlement(requestParameters: ListSellerSettlementRequest): Promise<PaginatedDto> {
        const response = await this.listSellerSettlementRaw(requestParameters);
        return await response.value();
    }

    /**
     * Listar todas as liquidações detalhadas sem paginação
     */
    async noPaginatedListAllSettlementDetailedWithRaw(requestParameters: NoPaginatedListAllSettlementDetailedWithRequest): Promise<runtime.ApiResponse<PaginatedDto>> {
        const queryParameters: any = {};

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = requestParameters.startDate;
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = requestParameters.endDate;
        }

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.currentPage !== undefined) {
            queryParameters['current_page'] = requestParameters.currentPage;
        }

        if (requestParameters.orderByField !== undefined) {
            queryParameters['order_by_field'] = requestParameters.orderByField;
        }

        if (requestParameters.orderByValue !== undefined) {
            queryParameters['order_by_value'] = requestParameters.orderByValue;
        }

        if (requestParameters.sellerId !== undefined) {
            queryParameters['sellerId'] = requestParameters.sellerId;
        }

        if (requestParameters.merchantId !== undefined) {
            queryParameters['merchant_id'] = requestParameters.merchantId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/settlement/unpaginatedList`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedDtoFromJSON(jsonValue));
    }

    /**
     * Listar todas as liquidações detalhadas sem paginação
     */
    async noPaginatedListAllSettlementDetailedWith(requestParameters: NoPaginatedListAllSettlementDetailedWithRequest): Promise<PaginatedDto> {
        const response = await this.noPaginatedListAllSettlementDetailedWithRaw(requestParameters);
        return await response.value();
    }

    /**
     * Listar detalhes de liquidação
     */
    async settlementBatchControllerGetSettlementBatchRaw(requestParameters: SettlementBatchControllerGetSettlementBatchRequest): Promise<runtime.ApiResponse<GetSettlementResponseDto>> {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling settlementBatchControllerGetSettlementBatch.');
        }

        if (requestParameters.currentPage === null || requestParameters.currentPage === undefined) {
            throw new runtime.RequiredError('currentPage','Required parameter requestParameters.currentPage was null or undefined when calling settlementBatchControllerGetSettlementBatch.');
        }

        if (requestParameters.settlementBatchId === null || requestParameters.settlementBatchId === undefined) {
            throw new runtime.RequiredError('settlementBatchId','Required parameter requestParameters.settlementBatchId was null or undefined when calling settlementBatchControllerGetSettlementBatch.');
        }

        const queryParameters: any = {};

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = requestParameters.startDate;
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = requestParameters.endDate;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.currentPage !== undefined) {
            queryParameters['current_page'] = requestParameters.currentPage;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.settlementStatus) {
            queryParameters['settlement_status'] = requestParameters.settlementStatus;
        }

        if (requestParameters.description !== undefined) {
            queryParameters['description'] = requestParameters.description;
        }

        if (requestParameters.amount !== undefined) {
            queryParameters['amount'] = requestParameters.amount;
        }

        if (requestParameters.originalAmount !== undefined) {
            queryParameters['original_amount'] = requestParameters.originalAmount;
        }

        if (requestParameters.type) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.formPayment) {
            queryParameters['form_payment'] = requestParameters.formPayment;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/settlement/{settlement_batch_id}`.replace(`{${"settlement_batch_id"}}`, encodeURIComponent(String(requestParameters.settlementBatchId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSettlementResponseDtoFromJSON(jsonValue));
    }

    /**
     * Listar detalhes de liquidação
     */
    async settlementBatchControllerGetSettlementBatch(requestParameters: SettlementBatchControllerGetSettlementBatchRequest): Promise<GetSettlementResponseDto> {
        const response = await this.settlementBatchControllerGetSettlementBatchRaw(requestParameters);
        return await response.value();
    }

    /**
     * Atualizar lote de liquidação
     */
    async settlementBatchControllerUpdateSettlementBatchRaw(requestParameters: SettlementBatchControllerUpdateSettlementBatchRequest): Promise<runtime.ApiResponse<SettlementBatch>> {
        if (requestParameters.settlementBatchId === null || requestParameters.settlementBatchId === undefined) {
            throw new runtime.RequiredError('settlementBatchId','Required parameter requestParameters.settlementBatchId was null or undefined when calling settlementBatchControllerUpdateSettlementBatch.');
        }

        if (requestParameters.updateSettlementBatchDTO === null || requestParameters.updateSettlementBatchDTO === undefined) {
            throw new runtime.RequiredError('updateSettlementBatchDTO','Required parameter requestParameters.updateSettlementBatchDTO was null or undefined when calling settlementBatchControllerUpdateSettlementBatch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/settlement/{settlement_batch_id}`.replace(`{${"settlement_batch_id"}}`, encodeURIComponent(String(requestParameters.settlementBatchId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateSettlementBatchDTOToJSON(requestParameters.updateSettlementBatchDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SettlementBatchFromJSON(jsonValue));
    }

    /**
     * Atualizar lote de liquidação
     */
    async settlementBatchControllerUpdateSettlementBatch(requestParameters: SettlementBatchControllerUpdateSettlementBatchRequest): Promise<SettlementBatch> {
        const response = await this.settlementBatchControllerUpdateSettlementBatchRaw(requestParameters);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum ListAllSettlementStatusEnum {
    Pending = 'pending',
    Paid = 'paid',
    Sent = 'sent',
    PendingCancel = 'pending_cancel',
    Canceled = 'canceled',
    Failed = 'failed',
    Resent = 'resent'
}
/**
    * @export
    * @enum {string}
    */
export enum ListAllSettlementTypeEnum {
    Pix = 'PIX',
    Ted = 'TED'
}
/**
    * @export
    * @enum {string}
    */
export enum ListAllSettlementOrderByFieldEnum {
    Status = 'status',
    Date = 'date',
    FormPayment = 'form_payment'
}
/**
    * @export
    * @enum {string}
    */
export enum ListAllSettlementOrderByValueEnum {
    Cres = 'cres',
    Desc = 'desc'
}
/**
    * @export
    * @enum {string}
    */
export enum ListAllSettlementDetailedStatusEnum {
    Pending = 'pending',
    Paid = 'paid',
    Sent = 'sent',
    PendingCancel = 'pending_cancel',
    Canceled = 'canceled',
    Failed = 'failed',
    Resent = 'resent'
}
/**
    * @export
    * @enum {string}
    */
export enum ListAllSettlementDetailedTypeEnum {
    Pix = 'PIX',
    Ted = 'TED'
}
/**
    * @export
    * @enum {string}
    */
export enum ListAllSettlementDetailedOrderByFieldEnum {
    Status = 'status',
    Date = 'date',
    FormPayment = 'form_payment'
}
/**
    * @export
    * @enum {string}
    */
export enum ListAllSettlementDetailedOrderByValueEnum {
    Cres = 'cres',
    Desc = 'desc'
}
/**
    * @export
    * @enum {string}
    */
export enum ListSellerSettlementStatusEnum {
    Pending = 'pending',
    Paid = 'paid',
    Sent = 'sent',
    PendingCancel = 'pending_cancel',
    Canceled = 'canceled',
    Failed = 'failed',
    Resent = 'resent'
}
/**
    * @export
    * @enum {string}
    */
export enum ListSellerSettlementTypeEnum {
    Pix = 'PIX',
    Ted = 'TED'
}
/**
    * @export
    * @enum {string}
    */
export enum ListSellerSettlementOrderByFieldEnum {
    Status = 'status',
    Date = 'date',
    FormPayment = 'form_payment'
}
/**
    * @export
    * @enum {string}
    */
export enum ListSellerSettlementOrderByValueEnum {
    Cres = 'cres',
    Desc = 'desc'
}
/**
    * @export
    * @enum {string}
    */
export enum NoPaginatedListAllSettlementDetailedWithStatusEnum {
    Pending = 'pending',
    Paid = 'paid',
    Sent = 'sent',
    PendingCancel = 'pending_cancel',
    Canceled = 'canceled',
    Failed = 'failed',
    Resent = 'resent'
}
/**
    * @export
    * @enum {string}
    */
export enum NoPaginatedListAllSettlementDetailedWithTypeEnum {
    Pix = 'PIX',
    Ted = 'TED'
}
/**
    * @export
    * @enum {string}
    */
export enum NoPaginatedListAllSettlementDetailedWithOrderByFieldEnum {
    Status = 'status',
    Date = 'date',
    FormPayment = 'form_payment'
}
/**
    * @export
    * @enum {string}
    */
export enum NoPaginatedListAllSettlementDetailedWithOrderByValueEnum {
    Cres = 'cres',
    Desc = 'desc'
}
/**
    * @export
    * @enum {string}
    */
export enum SettlementBatchControllerGetSettlementBatchSortEnum {
    Descending = 'time-descending',
    Ascending = 'time-ascending'
}
/**
    * @export
    * @enum {string}
    */
export enum SettlementBatchControllerGetSettlementBatchSettlementStatusEnum {
    Pending = 'pending',
    Paid = 'paid',
    Sent = 'sent',
    PendingCancel = 'pending_cancel',
    Canceled = 'canceled',
    Failed = 'failed',
    Resent = 'resent'
}
/**
    * @export
    * @enum {string}
    */
export enum SettlementBatchControllerGetSettlementBatchTypeEnum {
    Boleto = 'boleto',
    Pos = 'pos',
    PaymentLink = 'payment_link',
    ProtestLink = 'protest_link',
    Online = 'online',
    AutoGeneratePaymentLink = 'auto_generate_payment_link',
    AutoGenerateProtestLink = 'auto_generate_protest_link',
    BilletCode = 'billet_code',
    BillPayment = 'bill_payment',
    Digital = 'digital'
}
/**
    * @export
    * @enum {string}
    */
export enum SettlementBatchControllerGetSettlementBatchFormPaymentEnum {
    Credit = 'credit',
    Debit = 'debit',
    Pix = 'pix',
    Billet = 'billet',
    BilletCode = 'billet_code',
    PixPos = 'pix_pos'
}
