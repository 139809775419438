/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetPartnerEnterpriseBasicInformationResponseDto
 */
export interface GetPartnerEnterpriseBasicInformationResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetPartnerEnterpriseBasicInformationResponseDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof GetPartnerEnterpriseBasicInformationResponseDto
     */
    legalNature: string;
    /**
     * 
     * @type {string}
     * @memberof GetPartnerEnterpriseBasicInformationResponseDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof GetPartnerEnterpriseBasicInformationResponseDto
     */
    phoneNumber: string;
    /**
     * 
     * @type {number}
     * @memberof GetPartnerEnterpriseBasicInformationResponseDto
     */
    patrimonyAmount: number;
    /**
     * 
     * @type {number}
     * @memberof GetPartnerEnterpriseBasicInformationResponseDto
     */
    yearlyIncomeAmount: number;
    /**
     * 
     * @type {number}
     * @memberof GetPartnerEnterpriseBasicInformationResponseDto
     */
    monthlyAvgRevenueAmount: number;
}

export function GetPartnerEnterpriseBasicInformationResponseDtoFromJSON(json: any): GetPartnerEnterpriseBasicInformationResponseDto {
    return GetPartnerEnterpriseBasicInformationResponseDtoFromJSONTyped(json, false);
}

export function GetPartnerEnterpriseBasicInformationResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetPartnerEnterpriseBasicInformationResponseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'legalNature': json['legalNature'],
        'email': json['email'],
        'phoneNumber': json['phoneNumber'],
        'patrimonyAmount': json['patrimonyAmount'],
        'yearlyIncomeAmount': json['yearlyIncomeAmount'],
        'monthlyAvgRevenueAmount': json['monthlyAvgRevenueAmount'],
    };
}

export function GetPartnerEnterpriseBasicInformationResponseDtoToJSON(value?: GetPartnerEnterpriseBasicInformationResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'legalNature': value.legalNature,
        'email': value.email,
        'phoneNumber': value.phoneNumber,
        'patrimonyAmount': value.patrimonyAmount,
        'yearlyIncomeAmount': value.yearlyIncomeAmount,
        'monthlyAvgRevenueAmount': value.monthlyAvgRevenueAmount,
    };
}


