/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Celcoin,
    CelcoinFromJSON,
    CelcoinFromJSONTyped,
    CelcoinToJSON,
    GetBilletDto,
    GetBilletDtoFromJSON,
    GetBilletDtoFromJSONTyped,
    GetBilletDtoToJSON,
    GetSalePayerDto,
    GetSalePayerDtoFromJSON,
    GetSalePayerDtoFromJSONTyped,
    GetSalePayerDtoToJSON,
    GetSalePlanPaymentTypeDto,
    GetSalePlanPaymentTypeDtoFromJSON,
    GetSalePlanPaymentTypeDtoFromJSONTyped,
    GetSalePlanPaymentTypeDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetSellerSaleDto
 */
export interface GetSellerSaleDto {
    /**
     * 
     * @type {string}
     * @memberof GetSellerSaleDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetSellerSaleDto
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof GetSellerSaleDto
     */
    created_at: string;
    /**
     * 
     * @type {number}
     * @memberof GetSellerSaleDto
     */
    original_amount: number;
    /**
     * 
     * @type {string}
     * @memberof GetSellerSaleDto
     */
    type: GetSellerSaleDtoTypeEnum;
    /**
     * 
     * @type {GetSalePlanPaymentTypeDto}
     * @memberof GetSellerSaleDto
     */
    payment_type: GetSalePlanPaymentTypeDto;
    /**
     * 
     * @type {GetSalePayerDto}
     * @memberof GetSellerSaleDto
     */
    payer: GetSalePayerDto;
    /**
     * 
     * @type {GetBilletDto}
     * @memberof GetSellerSaleDto
     */
    billet: GetBilletDto;
    /**
     * 
     * @type {Celcoin}
     * @memberof GetSellerSaleDto
     */
    celcoin: Celcoin;
}

/**
* @export
* @enum {string}
*/
export enum GetSellerSaleDtoTypeEnum {
    Boleto = 'boleto',
    Pos = 'pos',
    PaymentLink = 'payment_link',
    ProtestLink = 'protest_link',
    Online = 'online',
    AutoGeneratePaymentLink = 'auto_generate_payment_link',
    AutoGenerateProtestLink = 'auto_generate_protest_link',
    BilletCode = 'billet_code',
    BillPayment = 'bill_payment',
    Digital = 'digital'
}

export function GetSellerSaleDtoFromJSON(json: any): GetSellerSaleDto {
    return GetSellerSaleDtoFromJSONTyped(json, false);
}

export function GetSellerSaleDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetSellerSaleDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'description': json['description'],
        'created_at': json['created_at'],
        'original_amount': json['original_amount'],
        'type': json['type'],
        'payment_type': GetSalePlanPaymentTypeDtoFromJSON(json['payment_type']),
        'payer': GetSalePayerDtoFromJSON(json['payer']),
        'billet': GetBilletDtoFromJSON(json['billet']),
        'celcoin': CelcoinFromJSON(json['celcoin']),
    };
}

export function GetSellerSaleDtoToJSON(value?: GetSellerSaleDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'description': value.description,
        'created_at': value.created_at,
        'original_amount': value.original_amount,
        'type': value.type,
        'payment_type': GetSalePlanPaymentTypeDtoToJSON(value.payment_type),
        'payer': GetSalePayerDtoToJSON(value.payer),
        'billet': GetBilletDtoToJSON(value.billet),
        'celcoin': CelcoinToJSON(value.celcoin),
    };
}


